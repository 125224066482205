import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useAppState } from "../../../states/app-state";
import {
  Avatar,
  AvatarProps,
  Button,
  ButtonProps,
  styled,
} from "@mui/material";
import { fetchDownloadUrl } from "../../../api/bearer-api";
import PersonIcon from "@mui/icons-material/Person";
import DisabledClickLoading from "../../../components/DisabledClickLoading";
import { Dialog, DialogActions, DialogContent } from "@mui/material";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const MyButton = styled(Button)<ButtonProps>(() => ({
  borderRadius: "100%",
  padding: "0px",
  backgroundColor: "#eeeeee",
  "& > *": {
    width: "150px",
    height: "150px",
  },
  cursor: "pointer",
}));

const MyPersonalIcon = styled(PersonIcon)(() => ({
  color: "#ffffff",
  fontSize: "70px",
}));

const MyDiv = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  padding: "10px 0",
}));

const MyAvatar = styled(Avatar)<AvatarProps>(() => ({
  width: "150px",
  height: "150px",
  "&>*": {
    objectFit: "cover",
    objectPosition: "top",
  },
}));

const MyInput = styled("input")(() => ({
  display: "none",
}));

export function readFile(file: File): Promise<string | null> {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => resolve(reader.result as string),
      false
    );
    reader.readAsDataURL(file);
  });
}

const createImage = (url: string): Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

export async function compressImage(imageSrc: string): Promise<Blob | null> {
  const image = await createImage(imageSrc);
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");
  if (!ctx) return null;

  const imageType = "image/JPEG";

  canvas.width = image.width;
  canvas.height = image.height;

  ctx.drawImage(image, 0, 0);

  return new Promise((resolve) => {
    canvas.toBlob(
      (file) => {
        resolve(file);
      },
      imageType,
      0.9
    );
  });
}

const AvatarEditor: React.FC = observer(() => {
  const appState = useAppState();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [preview, setPreview] = React.useState("");
  const [loading, setLoading] = useState(false);

  const [image, setImage] = useState();
  const [cropper, setCropper] = useState<any>();
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const uploadProfileImage = async (image: string) => {
    if (!image) return;
    const compressedImage = await compressImage(image);
    if (!compressedImage) return;
    try {
      setLoading(true);
      await appState.registration.personalDetails.updateAvatar(compressedImage);
      downloadAvatarImage();
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  const downloadAvatarImage = async () => {
    const image = appState.registration.personalDetails.avatar;
    if (image) {
      (async () => {
        const result = await fetchDownloadUrl(image);
        if (result) {
          setPreview(result);
        }
      })();
    } else {
      setPreview("");
    }
  };

  const getCropData = () => {
    setDialogOpen(false);
    if (typeof cropper !== "undefined") {
      uploadProfileImage(cropper.getCroppedCanvas().toDataURL());
    }
  };

  useEffect(() => {
    downloadAvatarImage();
  }, [downloadAvatarImage, appState.registration.personalDetails.avatar]);

  const onImageChange = (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }
    const reader = new FileReader();
    reader.onload = () => {
      setDialogOpen(true);
      setImage(reader.result as any);
    };
    reader.readAsDataURL(files[0]);
  };
  return (
    <div>
      <MyDiv>
        <MyButton
          className="personal-detail-avatar"
          onClick={(event: any) => {
            event.preventDefault();
            fileInputRef.current?.click();
          }}
        >
          {!preview && <MyPersonalIcon />}
          {preview && <MyAvatar src={preview} />}
        </MyButton>
        <MyInput
          type="file"
          ref={fileInputRef}
          accept="image/*"
          disabled={loading}
          value={fileInputRef.current?.value || ""}
          onChange={onImageChange}
        />
        {loading && <DisabledClickLoading />}
      </MyDiv>
      <Dialog open={dialogOpen}>
        <DialogContent>
          <Cropper
            style={{ height: 400, width: "100%" }}
            dragMode={"move"}
            initialAspectRatio={1}
            src={image}
            cropBoxResizable={false}
            background={false}
            onInitialized={(instance) => {
              setCropper(instance);
            }}
            autoCropArea={0.4}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            onClick={() => setDialogOpen(false)}
          >
            Cancel
          </Button>
          <Button color="primary" variant="contained" onClick={getCropData}>
            Crop Image and Upload
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
});
export default AvatarEditor;
