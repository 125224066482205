
import Autocomplete from "react-google-autocomplete";
import { AddressType } from "../../../states/app-state";

const AutocompleteAddress: React.FC<{ onChange: (place: AddressType | null) => void }> = ({ onChange }) => {
    return (
        <Autocomplete
            apiKey={process.env.REACT_APP_ApiKey}
            style={{
                width: '100%', backgroundColor: '#e6e6e6', border: 'none', borderBottom: '1px solid black', borderRadius: 2,
                height: 50, padding: 5, outline: 'none', marginBottom: '10px'
            }}
            onPlaceSelected={(place) => onChange(place)}
            placeholder="Enter an Address"
            options={{
                types: [],
                strictBounds: true,
                componentRestrictions: { country: 'au' },
            }}
        />
    )
}
export default AutocompleteAddress;