import React from "react";
import { Box } from "@mui/material";
import LeftSide from "./LeftSidePanel";
import RightSide from "./rightSide/RightSide";
import { observer } from "mobx-react-lite";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";


const RegistrationSteps: React.FC = observer(() => {
  return (
    <div className="info-wrapper">
      <Header />
      <div className="inside-info-wrapper">
        <Box sx={{ display: { sm: "block", xs: "none" } }}>
          <LeftSide />
        </Box>
        <RightSide />
      </div>
      <Footer />
    </div>
  );
});
export default RegistrationSteps;
