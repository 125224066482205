import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useAppState } from "../../states/app-state";
import { Button, ButtonProps, InputProps, styled } from "@mui/material";
import DisabledClickLoading from "../../components/DisabledClickLoading";

const MyButton = styled(Button)<ButtonProps>(() => ({
  borderRadius: "100%",
  padding: "0px",
  backgroundColor: "#eeeeee",
  "& > *": {
    width: "85px",
    height: "85px",
  },
  cursor: "pointer",
}));

const MyDiv = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  padding: "10px 0",
}));

type BearerRegoCertificateProps = InputProps & {
  validator?: (file: string) => string | undefined;
  onValidate?: (v: boolean) => void;
};

const RegoCertificate: React.FC<BearerRegoCertificateProps> = observer(
  ({ validator, onValidate, ...props }) => {
    const appState = useAppState();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const uploadRegoCertificateFile = async (file: File) => {
      const a = file.type.split("/");
      const type = a[1];
      try {
        if (
          !(
            type === "pdf" ||
            type === "jpeg" ||
            type === "jpg" ||
            type === "png"
          )
        ) {
          alert("your file must be PDF or JPEG or JPG or PNG");
        } else {
          setLoading(true);
          await appState.registration.modesOfTransport.uploadRegoCertificateFile(
            file
          );
          setLoading(false);
          appState.registration.modesOfTransport.setIsRegoCertificateUrlHasChanged(
            true
          );
        }
      } catch (err: any) {
        setLoading(false);
      }
    };

    return (
      <MyDiv>
        <MyButton
          onClick={(event: any) => {
            event.preventDefault();
            fileInputRef.current?.click();
          }}
        ></MyButton>
        <input
          type="file"
          ref={fileInputRef}
          accept="image/*"
          onChange={(event: any) => {
            const file = event.target.files[0];
            if (file) {
              uploadRegoCertificateFile(file);
            }
          }}
          value={fileInputRef.current?.value || ""}
          disabled={loading}
        />
        {loading && <DisabledClickLoading />}
      </MyDiv>
    );
  }
);
export default RegoCertificate;
