import React from 'react';
import { SvgIconProps } from '@mui/material';

const MotorBikeIcon: React.FC<SvgIconProps> = (props) => {
    return <svg id="Layer_1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 500 500" style={{ width: '60px' }}>
        <path fill="#a1a5b2" fillRule="evenodd" d="M139.93,398.6c25.45,0,46.11,2.21,46.11,4.94s-20.66,4.94-46.11,4.94-46.11-2.21-46.11-4.94,20.66-4.94,46.11-4.94Z" />
        <path fill="#37353e" fillRule="evenodd" d="M134.5,280.43A61.11,61.11,0,1,1,73.4,341.53a61.14,61.14,0,0,1,61.11-61.11Zm0,37.89a23.22,23.22,0,1,1-23.22,23.22,23.23,23.23,0,0,1,23.22-23.22Z" />
        <path fill="#848484" fillRule="evenodd" d="M134.5,318.19a23.22,23.22,0,1,1-23.22,23.22,23.23,23.23,0,0,1,23.22-23.22Z" />
        <path fill="#0d346d" fillRule="evenodd" d="M349.47,134.94s-38.06-.53-38.86,7.35c-2.68,26.16,14.71,89.11,7.9,121.51-.47,2.25-13.35,52.61-57.31,70.91-30.69,11.26-60-3.76-65.87-24.3-3.94-13.68,2.45-29.89,14.66-41.73l-30.45-7.9H125.39s-72.75,15.79-73.88,96.44l64.09.38c-.34,4.78.4,8.78,3.76,9.06,32.83,2.74,196.95-9.55,196.95-9.55l39.74-86.29,11.86-38.2Z" />
        <path fillRule="evenodd" d="M414,398.6c25.45,0,46.11,2.21,46.11,4.94s-20.66,4.94-46.11,4.94-46.11-2.21-46.11-4.94,20.66-4.94,46.11-4.94Z" />
        <path fill="#14438e" fillRule="evenodd" d="M449.11,248.94c-17.24-16.64-36.92-24-44-25.38-6.2-16-29.11-81.68-39.19-92.39a2.53,2.53,0,0,0-1.22-.66l-2.46-.17c-3.65-.54-9.41-3.45-10.15-16.29-.38-6.56,7.25-22.23,7.25-22.23s-29.2-1.57-37.55,2.47A21.51,21.51,0,0,0,309.28,116c.49,6.78,4.61,14.86,9.22,18.91s18.44,5.38,18.44,5.38l.63-.22c-1.17.88-1.88,1.64-1.94,2.2-2,17.57,25.84,78.67,22.4,94.28-9.77,44.24-56.94,114-71.14,117.91-16,4.44-235.2,1.47-235.2,1.47l.66,12.19s285.9,5.56,293.61-1.47c2-1.85-24.59-40.86,23.09-80a75.06,75.06,0,0,1,46.29-11.25c34.19,2.85,54.78,32.07,56.34,31.6C473.8,306.39,465.47,264.73,449.11,248.94Z" />
        <path fill="#37353e" fillRule="evenodd" d="M408.59,280.43a61.11,61.11,0,1,1-61.11,61.11,61.14,61.14,0,0,1,61.11-61.11Zm0,37.89a23.22,23.22,0,1,1-23.22,23.22,23.23,23.23,0,0,1,23.22-23.22Z" />
        <path fill="#848484" fillRule="evenodd" d="M408.59,318.19a23.22,23.22,0,1,1-23.22,23.22,23.23,23.23,0,0,1,23.22-23.22Z" />
        <rect fill="#383540" x="47" y="260.22" width="99.82" height="8.46" />
        <path fill="#383540" fillRule="evenodd" d="M113,236c12.41-6.77,97.39-5.45,107.16,3.38C232,250.07,210,268.68,210,268.68H107.91S104.2,240.76,113,236Z" />
        <path fill="#ff8a29" fillRule="evenodd" d="M357.7,91.82c4.18,0,7.57,8.62,7.57,19.25s-3.39,19.25-7.57,19.25-7.57-8.62-7.57-19.25,3.39-19.25,7.57-19.25Z" />
        <path fill="#2560b7" fillRule="evenodd" d="M306.82,109.15a6.76,6.76,0,1,1-6.76,6.76,6.76,6.76,0,0,1,6.76-6.76Z" />
        <rect fill="#ff8a29" x="49.26" y="202.69" width="55.83" height="55.83" />
    </svg>


}
export default MotorBikeIcon;