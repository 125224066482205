import React from "react";
import { Step, StepLabel, Stepper } from "@mui/material";
import { AuthenticationStepsEnumValues, useAppState } from "../../states/app-state";

const HorizontalLinearStepper: React.FC = () => {
    const appState = useAppState();
    const activeStep = AuthenticationStepsEnumValues.findIndex(s => s === appState.registration.signUp.authentication);
    return (
        <>
            <Stepper activeStep={activeStep} sx={{ marginTop: 5, marginBottom: 5 }}>
                {AuthenticationStepsEnumValues.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};

                    return (
                        <Step key={label} {...stepProps}>
                            <StepLabel {...labelProps}>{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </>

    )
}
export default HorizontalLinearStepper;