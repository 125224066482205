import React from "react";
import BearerTextField from "../../components/fields/BearerTextField";
import { observer, Observer } from "mobx-react-lite";
import {
  FormControl,
  InputAdornment,
  InputLabel,
  FilledInput,
  Button,
  FormHelperText,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import { useAppState } from "../../states/app-state";
import { validateEmail } from "../../components/validation/FieldsValidation";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import ResetPasswordPage from "./ResetPasswordPage";
import { log } from "../../components/error/error";
const LoginPage: React.FC = observer(() => {
  const appState = useAppState();
  const login = appState.login;
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };
  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    try {
      setLoading(true);
      setError("");
      await appState.login.login();
      setLoading(false);
    } catch (err: any) {
      log(err);
      setLoading(false);

      switch (err.message) {
        case "Firebase: Error (auth/wrong-password).":
          setError("Incorrect password.");
          return;
        case "Firebase: Error (auth/user-not-found).":
          setError("User doesn’t exist");
          return;
        case "Firebase: Error (auth/network-request-failed.":
          setError(
            "We are experiencing network issues, please check your internet connection and try again."
          );
          return;
        case "Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).":
          setError(
            "Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later."
          );
          return;
        default:
          setError(err?.message);
          break;
      }
    }
  };

  const forgetPasswordHandler = () => {
    appState.login.setEditing(false);
  };
  return (
    <div>
      {login.editing && (
        <>
          <div className="login-input-wrapper">
            <>
              <form onSubmit={handleSubmit}>
                <Observer>
                  {() => (
                    <BearerTextField
                      sx={{ paddingBottom: "10px" }}
                      label="Email"
                      type="email"
                      name="email"
                      className="input-text"
                      autoComplete="email"
                      value={login.email}
                      onChange={(e: any) => login.setEmail(e.target.value)}
                      onValidate={(v: any) => login.setEmailValid(v)}
                      validator={validateEmail}
                    />
                  )}
                </Observer>
                <Observer>
                  {() => (
                    <>
                      <FormControl
                        variant="filled"
                        fullWidth
                        size="small"
                        sx={{ paddingBottom: "10px" }}
                      >
                        <InputLabel>Password</InputLabel>
                        <FilledInput
                          value={login.password}
                          className="input-text"
                          type={showPassword ? "text" : "password"}
                          name="current-password"
                          autoComplete="current-password"
                          onChange={(e: any) =>
                            login.setPassword(e.target.value)
                          }
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? (
                                  <VisibilityOff />
                                ) : (
                                  <Visibility />
                                )}
                              </IconButton>
                            </InputAdornment>
                          }
                        />
                        {error && (
                          <FormHelperText error={!!error}>
                            {error}
                          </FormHelperText>
                        )}
                      </FormControl>
                    </>
                  )}
                </Observer>
                <Observer>
                  {() => {
                    const valid = !!login.emailValid && !!login.password;
                    return (
                      <>
                        <Button
                          type="submit"
                          variant="contained"
                          fullWidth
                          disabled={!valid}
                          className="login-btn"
                        >
                          Log in
                        </Button>
                        {loading && <DisabledClickLoading />}
                      </>
                    );
                  }}
                </Observer>
                <Observer>
                  {() => (
                    <span>
                      <Button
                        onClick={forgetPasswordHandler}
                        className="forget-password"
                      >
                        Forgot password?
                      </Button>
                    </span>
                  )}
                </Observer>
              </form>
            </>
          </div>
        </>
      )}
      {!login.editing && <ResetPasswordPage />}
    </div>
  );
});
export default LoginPage;
