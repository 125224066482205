import { AddressType } from "../../../states/app-state";

export function extractPostCode(place: AddressType | null): string {
    const postalCode = place?.address_components.find((p) => p.types[0] === "postal_code");
    return postalCode?.long_name!;
}
export function extractStreetName(place: AddressType | null): string {
    const streetName = place?.address_components.find((p) => p.types[0] === "route");
    return streetName?.long_name!;
}
export function extractStreetNumber(place: AddressType | null): string {
    const streetNumber = place?.address_components.find((p) => p.types[0] === "street_number");
    return streetNumber?.long_name!;
}
export function extractCity(place: AddressType | null): string {
    const city = place?.address_components.find((p) => p.types[0] === "locality");
    return city?.long_name!;
}
export function extractState(place: AddressType | null): string {
    const state = place?.address_components.find((p) => p.types[0] === "administrative_area_level_1");
    return state?.short_name!;
}
export function extractUnitNumber(place: AddressType | null): string {
    const unitNumber = place?.address_components.find((p) => p.types[0] === "subpremise");
    return unitNumber?.short_name!;
}
