import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, ButtonProps, InputProps, styled } from "@mui/material";
import { useAppState } from "../../../states/app-state";
import DisabledClickLoading from "../../../components/DisabledClickLoading";

const MyButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: "100%",
  padding: theme.spacing(0),
  backgroundColor: theme.palette.grey[200],
  "& > *": {
    width: "85px",
    height: "85px",
  },
  cursor: "pointer",
}));

const MyDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  padding: "10px 0",
}));

type BearerInsuranceFiledProps = InputProps & {
  validator?: (file: string) => string | undefined;
  onValidate?: (v: boolean) => void;
};

const PublicLiabilityInsuranceFile: React.FC<BearerInsuranceFiledProps> =
  observer(({ validator, onValidate, ...props }) => {
    const appState = useAppState();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const uploadPublicLiabilityInsuranceFile = async (file: File) => {
      const a = file.type.split("/");
      const type = a[1];
      try {
        if (
          !(
            type === "pdf" ||
            type === "jpeg" ||
            type === "jpg" ||
            type === "png"
          )
        ) {
          alert("your file must be PDF or JPEG or JPG or PNG");
        } else {
          setLoading(true);
          await appState.registration.legalDetails.uploadPublicLiabilityInsuranceFile(
            file
          );
          setLoading(false);
        }
      } catch (err: any) {
        setLoading(false);
      }
    };

    return (
      <MyDiv>
        <MyButton
          onClick={(event: any) => {
            event.preventDefault();
            fileInputRef.current?.click();
          }}
        ></MyButton>
        <input
          type="file"
          ref={fileInputRef}
          accept="image/*"
          onChange={(event: any) => {
            const file = event.target.files[0];
            if (file) {
              uploadPublicLiabilityInsuranceFile(file);
            }
          }}
          value={fileInputRef.current?.value || ""}
          disabled={loading}
        />
        {loading && <DisabledClickLoading />}
      </MyDiv>
    );
  });
export default PublicLiabilityInsuranceFile;
