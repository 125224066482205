import { Backdrop, BackdropProps, CircularProgress, styled } from "@mui/material";


const MyBackdrop = styled(Backdrop)<BackdropProps>(() => ({
    // zIndex: theme.zIndex.drawer + 1,
    zIndex: "1",
    color: "#ffffff",
    '& .container': {
        width: '100%',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
}));


export default function DisabledClickLoading() {
    return (
        <MyBackdrop open={true}>
            <div onClick={(e) => { e.preventDefault(); e.stopPropagation(); }}>
                <CircularProgress color="inherit" />
            </div>
        </MyBackdrop>

    )
}