import React from "react";
import { Stepper, Step, StepLabel } from "@mui/material";
import { StepsEnumValues, useAppState } from "../../states/app-state";
import { observer } from "mobx-react-lite";

const VerticalLinearStepper: React.FC = observer(() => {
    const appState = useAppState();
    const activeStep = StepsEnumValues.findIndex(s => s === appState.registration.step);
    return (
        <>
            <Stepper activeStep={activeStep} orientation="vertical" sx={{ paddingTop: '15px' }}>
                {StepsEnumValues.map((label, index) => {
                    const stepProps: { completed?: boolean } = {};
                    const labelProps: {
                        optional?: React.ReactNode;
                    } = {};

                    return (
                        <Step key={index} {...stepProps}>
                            <StepLabel {...labelProps} >{label}</StepLabel>
                        </Step>
                    );
                })}
            </Stepper>
        </>
    );
});

export default VerticalLinearStepper;
