import { CssBaseline, ThemeProvider } from "@mui/material";

import Scaffold from "./containers/Scaffold";
import { AppStateProvider } from "./states/app-state";
import theme from "./theme";

function App() {
  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AppStateProvider>
          <Scaffold />
        </AppStateProvider>
      </ThemeProvider>
    </>
  );
}

export default App;
