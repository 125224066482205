import React, { useState } from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Typography,
  TextField,
  MenuItem,
} from "@mui/material";
import { useAppState, ReferredByEnumValues } from "../../states/app-state";
import { observer, Observer } from "mobx-react-lite";
import BearerStandardTextField from "../../components/fields/BearerStandardTextField";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import LogoutButton from "../login/LogoutButton";
import { validateOnlyMoreThanTwoLetter } from "../../components/validation/FieldsValidation";
import { log } from "../../components/error/error";
import BearerTextField from "../../components/fields/BearerTextField";
import WhatsAppFeedBack from "../../components/common/WhatsAppFeedBack";

const DigitalSignature: React.FC = observer(() => {
  const appState = useAppState();

  React.useEffect(() => {
    appState.getOnboardingRemoteConfigParameters();
  }, [appState]);

  const { digitalSignature } = appState.registration;

  const privacy_policy =
    appState.onboardingRemoteConfigParameters?.rider_pp_url;

  const delivery_person_agreement =
    appState.onboardingRemoteConfigParameters?.rider_tc_url;

  const [loading, setLoading] = React.useState(false);
  const submitHandler = async (e: any) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    try {
      setLoading(true);
      await digitalSignature.setDigitalSignature();
      setLoading(false);
    } catch (err: any) {
      log(err);
      setLoading(false);
    }
  };

  const time = new Date(appState.registration.signUp.latestSignUpDate! * 1000)
    .toJSON()
    .split("T")[0];

  return (
    <>
      {appState.onboardingRemoteConfigParameters && <WhatsAppFeedBack />}
      <div style={{ marginBottom: "10px" }}>
        <LogoutButton />
      </div>
      <form onSubmit={submitHandler}>
        <Typography>
          To finalize your registration please confirm that you have reviewed
          and agreed all bellow “Terms and Conditions” (You have done that once
          on {time}), “Sign” this form by providing your full name on this page
          and then “Submit”.
        </Typography>
        <br />
        <FormGroup>
          <Grid container>
            <Grid item xs={10} sm={12} md={7}>
              <Observer>
                {() => (
                  <TextField
                    select
                    label="How did you hear about Bearer?"
                    value={digitalSignature.referredBy}
                    onChange={(e: any) => {
                      digitalSignature.setReferredBy(e.target.value);
                      if (e.target.value == "Other") {
                        digitalSignature.setReferredCode("");
                      } else if (e.target.value == "Referral Code") {
                        digitalSignature.setReferredCode("");
                      } else {
                        digitalSignature.setReferredCode(e.target.value);
                      }
                    }}
                    variant="filled"
                    fullWidth
                    size="small"
                    style={{ marginBottom: 20 }}
                  >
                    {ReferredByEnumValues.map((option, index) => (
                      <MenuItem key={index} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              </Observer>
              {digitalSignature.referredBy == "Referral Code" && (
                <Observer>
                  {() => (
                    <BearerTextField
                      label="Invite Code"
                      className="signature-invite-code"
                      value={digitalSignature.referredCode}
                      onChange={(e: any) =>
                        digitalSignature.setReferredCode(e.target.value)
                      }
                      style={{ marginBottom: 20 }}
                    />
                  )}
                </Observer>
              )}
            </Grid>
            <br />
            <br />
            <br />
            <Grid item xs={12} sm={12} md={12}>
              <Observer>
                {() => (
                  <FormControlLabel
                    control={<Checkbox checked />}
                    label={
                      <Link
                        href={privacy_policy}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Bearer Privacy Policy
                      </Link>
                    }
                  />
                )}
              </Observer>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Observer>
                {() => (
                  <FormControlLabel
                    control={<Checkbox checked />}
                    label={
                      <Link
                        href={delivery_person_agreement}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Bearer Contractor Agreement
                      </Link>
                    }
                  />
                )}
              </Observer>
            </Grid>
          </Grid>
        </FormGroup>
        <br />
        <Typography>
          I’ve read and agreed all above Terms and Conditions and I,{" "}
          <Observer>
            {() => (
              <BearerStandardTextField
                value={digitalSignature.name}
                onChange={(e: any) => digitalSignature.setName(e.target.value)}
                onValidate={(v: any) => digitalSignature.setNameValid(v)}
                validator={validateOnlyMoreThanTwoLetter}
              />
            )}
          </Observer>
          hereby declare, all of the information I have provided is complete and
          correct. I am aware that missing or incomplete information, whether
          deliberate or the result of negligence, may either result in rejection
          of my application or [Bearer] account(s) suspension for further
          investigations and legal actions. Also I understand that submitting
          this application will not guarantee that it would be accepted and will
          not mean that I have been accepted to work as a Bearer Service
          Provider or being allowed by the platform to access the applications
          (Mobile and Web) designed and deemed to be used by Bearer Service
          Providers (Bearers).
          <br />
          <b>
            The Electronic Transactions (Victoria) Act 2000 establishes the
            regulatory framework for transactions to be completed
            electronically. By ticking the above boxes, you have indicated your
            approval of the contents of this electronic communication.
          </b>
        </Typography>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center" sx={{ marginTop: 3 }}>
            <Observer>
              {() => {
                const referalValid = digitalSignature.referredBy == "" ? false : true;
                var referalCodeValid = false
                if (digitalSignature.referredBy == "Referral Code") {
                  if (digitalSignature.referredCode.trim().length < 3) {
                    referalCodeValid = false
                  } else {
                    referalCodeValid = true
                  }
                } else {
                  referalCodeValid = true
                }
                const valid = digitalSignature.nameValid && referalValid && referalCodeValid;
                return (
                  <>
                    <Button variant="contained" type="submit" disabled={!valid}>
                      SIGN & SUBMIT
                    </Button>
                    {loading && <DisabledClickLoading />}
                  </>
                );
              }}
            </Observer>
          </Grid>
        </Grid>
      </form>
    </>
  );
});
export default DigitalSignature;
