import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import { useAppState } from "../../states/app-state";
import { observer, Observer } from "mobx-react-lite";

const TermsAndConditions = observer(() => {
  const appState = useAppState();
  const agreeHandler = () => {
    appState.registration.setSignUpStep();
    appState.registration.signUp.setEmailAuthentication();
  };
  const resumeApplicationHandler = () => {
    window.location.href = '/'
    window.localStorage.setItem("activeTab", "1");
  };

  const disagreeHandler = () => {
    window.location.href = '/'
    window.localStorage.setItem("activeTab", "2");
  };

  React.useEffect(() => {
    appState.getOnboardingRemoteConfigParameters();
  }, [appState]);

  const privacy_policy =
    appState.onboardingRemoteConfigParameters?.rider_pp_url;

  const delivery_person_agreement =
    appState.onboardingRemoteConfigParameters?.rider_tc_url;

  const [firstChecked, setFirstChecked] = React.useState(false);
  const handleFirstChange = () => {
    setFirstChecked(!firstChecked);
  };


  const [ninthChecked, setNinthChecked] = React.useState(false);
  const handleNinthChange = () => {
    setNinthChecked(!ninthChecked);
  };

  return (
    <>
      <Typography>
        G’day and welcome to Bearer.
        <br />
        <br />
        In order to complete your registration during this onboarding process,
        we would ask you to provide some of your “Personal Information” that
        could be stored, used or shared for legal purposes including but not
        limited to: ID Verification, AML, Right to Work and Background checks in
        Australia by our third party partners.
        <br />
        <br />
        For all approved users, some of the provided details (deemed to be
        necessary for the platform to operate normally) would be kept on our
        servers (i.e: for keeping track of orders and financial transactions)
        and others would be only used by our Web / Mobile / Desktop applications
        for regular user interactions.
        <br />
        <br />
        For more information about our Terms of Service and your Obligations and
        the consents, please read all bellow documents carefully, tick the check
        boxes next to them and press AGREE to continue.
        <br />
        <br />
      </Typography>

      <FormGroup>
        <Observer>
          {() => (
            <FormControlLabel
              control={
                <Checkbox checked={firstChecked} onChange={handleFirstChange} />
              }
              label={
                <Link href={privacy_policy} target="_blank" rel="noreferrer">
                  Bearer Privacy Policy
                </Link>
              }
            />
          )}
        </Observer>
        <Observer>
          {() => (
            <FormControlLabel
              control={
                <Checkbox checked={ninthChecked} onChange={handleNinthChange} />
              }
              label={
                <Link
                  href={delivery_person_agreement}
                  target="_blank"
                  rel="noreferrer"
                >
                  Bearer Contractor Agreement
                </Link>
              }
            />
          )}
        </Observer>
      </FormGroup>
      <br />
      <Typography>
        Please confirm that you have reviewed, and agree to each of the provided
        documents above and you acknowledge that you must accept above Consents
        in order to start your application as a service provider with Bearer, by
        clicking AGREE bellow.
      </Typography>
      <br />

      <Grid container spacing={2}>
        {window.location.pathname === '/new' &&
          <Grid item xs={12} sm={4} textAlign="center" sx={{ marginTop: 3 }}>
            <Button
              variant="contained"
              onClick={resumeApplicationHandler}
            >
              Resume an Application
            </Button>
          </Grid>
        }
        <Grid item xs={12} sm={4} textAlign="center" sx={{ marginTop: 3 }}>
          <Button
            sx={{ minWidth: 100 }}
            variant="contained"
            onClick={disagreeHandler}
          >
            DISAGREE
          </Button>
        </Grid>
        <Grid item xs={12} sm={4} textAlign="center" sx={{ marginTop: 3 }}>
          <Observer>
            {() => {
              const valid = firstChecked && ninthChecked;
              // const valid = firstChecked && secondChecked && thirdChecked && fourthChecked && fifthChecked && sixthChecked &&
              //     seventhChecked && eighthChecked && ninthChecked
              return (
                <Button
                  sx={{ minWidth: 100 }}
                  variant="contained"
                  onClick={agreeHandler}
                  disabled={!valid}
                >
                  AGREE
                </Button>
              );
            }}
          </Observer>
        </Grid>
      </Grid>
    </>
  );
});
export default TermsAndConditions;
