import React from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useAppState } from "../states/app-state";

const CheckServerRunningStates: React.FC = () => {
    const appState = useAppState();
    const [error, setError] = React.useState(false);
    React.useEffect(() => {
        (async () => {
            if (!appState.serverChecked) {
                try {
                    await appState.checkServer();
                } catch (err) {
                    setError(true);
                }
            }
        })();
    }, [appState]);

    return (
        <>
            <NoCloudFunctions />
            <NoWebApp />
            {error && <ServerErrorPopup />}
        </>

    )
}
export default CheckServerRunningStates;

const NoCloudFunctions = observer(() => {
    const appState = useAppState();
    const noCloudFunctions = appState.noCloudFunctions;

    const handleClick = () => {
        window.location.replace(noCloudFunctions!.url);
    }

    return (
        <Dialog open={!!noCloudFunctions} >
            <DialogContent>
                <DialogContentText>
                    {noCloudFunctions?.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={handleClick}> {noCloudFunctions?.buttonAction}</Button>
            </DialogActions>
        </Dialog>

    )
})


const NoWebApp = observer(() => {
    const appState = useAppState();
    const noOnboarding = appState.noOnboarding;

    const handleClick = () => {
        window.location.replace(noOnboarding!.url);
    }

    return (
        <Dialog open={!!noOnboarding} >
            <DialogContent>
                <DialogContentText>
                    {noOnboarding?.text}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained" onClick={handleClick}> {noOnboarding?.buttonAction}</Button>
            </DialogActions>
        </Dialog>

    )
})



const ServerErrorPopup: React.FC = () => {
    const handleClick = () => {
        window.location.reload()
    }
    return (
        <Dialog open={true}>
            <DialogContent>
                <DialogContentText>
                    The service is temporarily unavailable, please try later.
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    variant="contained"
                    onClick={handleClick}
                >
                    Retry
                </Button>
            </DialogActions>
        </Dialog>
    )
}