import React, { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { Button, ButtonProps, InputProps, styled } from "@mui/material";
import { useAppState } from "../../../states/app-state";
import DisabledClickLoading from "../../../components/DisabledClickLoading";
import { compressImage } from "./AvatarEditor";
import { Dialog, DialogActions, DialogContent } from "@mui/material";

import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";

const MyButton = styled(Button)<ButtonProps>(({ theme }) => ({
  borderRadius: "100%",
  padding: theme.spacing(0),
  backgroundColor: theme.palette.grey[200],
  "& > *": {
    width: "85px",
    height: "85px",
  },
  cursor: "pointer",
}));

const MyDiv = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
  alignItems: "center",
  padding: "10px 0",
  width: "257px",
}));

type BearerDriversLicenseFileProps = InputProps & {
  validator?: (file: string) => string | undefined;
  onValidate?: (v: boolean) => void;
};

const DriversLicenseFile: React.FC<BearerDriversLicenseFileProps> = observer(
  ({ validator, onValidate, ...props }) => {
    const appState = useAppState();
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [loading, setLoading] = useState(false);
    const { personalDetails } = appState.registration;
    const [dialogOpen, setDialogOpen] = React.useState(false);

    const [image, setImage] = useState();
    const [cropper, setCropper] = useState<any>();

    const uploadDriversLicenseFile = async (file: string) => {
      const image = file;
      if (!image) return;
      const compressedImage = await compressImage(image);
      if (!compressedImage) return;
      try {
        setLoading(true);
        await appState.registration.personalDetails.uploadDriversLicenseFile(
          compressedImage
        );
        personalDetails.setIsIdPhotoCardUploaded(true);
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    };

    const getCropData = () => {
      setDialogOpen(false);
      if (typeof cropper !== "undefined") {
        uploadDriversLicenseFile(cropper.getCroppedCanvas().toDataURL());
      }
    };

    const onChange = (e: any) => {
      e.preventDefault();
      let files;
      if (e.dataTransfer) {
        files = e.dataTransfer.files;
      } else if (e.target) {
        files = e.target.files;
      }
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result as any);
        setDialogOpen(true);
      };
      reader.readAsDataURL(files[0]);
    };

    return (
      <div className="file-upload">
        <MyDiv>
          <MyButton
            onClick={(event: any) => {
              event.preventDefault();
              fileInputRef.current?.click();
            }}
          ></MyButton>
          <input
            type="file"
            ref={fileInputRef}
            accept="image/*"
            title=" "
            onChange={onChange}
            value={fileInputRef.current?.value || ""}
            disabled={loading}
          />
          {loading && <DisabledClickLoading />}
        </MyDiv>
        <Dialog open={dialogOpen}>
          <DialogContent>
            <Cropper
              style={{ height: 400, width: "100%" }}
              dragMode={"move"}
              initialAspectRatio={8.5 / 5.5}
              src={image}
              cropBoxResizable={false}
              background={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setDialogOpen(false)}
            >
              Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={getCropData}>
              Crop & Upload
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);
export default DriversLicenseFile;
