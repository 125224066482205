import whatsApp from "../../assets/whatsappLogo.svg";
import { useAppState } from "../../states/app-state";
const WhatsAppFeedBack = () => {
  const appState = useAppState();
  const whatsappSupport =
    appState.onboardingRemoteConfigParameters?.whatsappLiveSupport;
  return (
    <a
      className="whats-app-live-chat"
      href={whatsappSupport}
      target="_blank"
      rel="noreferrer"
    >
      <img src={whatsApp} alt="rider" />
    </a>
  );
};

export default WhatsAppFeedBack;
