import React from 'react';
import { SvgIconProps } from '@mui/material';

const BicycleIcon: React.FC<SvgIconProps> = (props) => {
    return <svg id="Layer_1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 500 500" style={{ width: '60px' }}>
        <rect fill="#14438e" x="92.38" y="227.06" width="201.93" height="10.3" transform="translate(310.48 -46.33) rotate(62.59)" />
        <path fill="#bebcd1" d="M478.72,318H408.32l64.81-27.48a.62.62,0,0,0-.48-1.14L408,316.79l49.64-49.72a.62.62,0,1,0-.88-.87L407,316l26.44-65.22a.62.62,0,0,0-1.15-.47l-26.4,65.11V245.17a.62.62,0,1,0-1.24,0v70.39l-27.47-64.81a.62.62,0,1,0-1.14.48l27.42,64.69-49.72-49.65a.62.62,0,0,0-.88.88l49.8,49.73-65.22-26.45a.62.62,0,1,0-.46,1.15L402.11,318H331.85a.62.62,0,1,0,0,1.24h70.39l-64.8,27.48a.62.62,0,1,0,.48,1.14l64.69-27.43L353,370.13a.62.62,0,0,0,.88.88l49.72-49.81-26.44,65.22a.62.62,0,0,0,1.15.46l26.4-65.11V392a.62.62,0,0,0,1.24,0V321.65l27.47,64.81a.62.62,0,1,0,1.14-.48l-27.42-64.69,49.71,49.65a.62.62,0,0,0,.88-.88l-49.8-49.73,65.22,26.45a.62.62,0,1,0,.46-1.15l-65.11-26.4h70.26a.62.62,0,1,0,0-1.24Z" />
        <path fill="#bebcd1" d="M169,317.89H98.57l64.81-27.48a.62.62,0,0,0-.48-1.14L98.21,316.69,147.85,267a.62.62,0,1,0-.88-.87L97.25,315.9l26.44-65.22a.62.62,0,0,0-1.15-.47l-26.4,65.11V245.06a.62.62,0,1,0-1.24,0v70.39L67.43,250.65a.62.62,0,1,0-1.14.48l27.42,64.69L44,266.17a.62.62,0,0,0-.88.88l49.8,49.73L27.71,290.33a.62.62,0,1,0-.46,1.15l65.11,26.4H22.09a.62.62,0,1,0,0,1.24H92.48L27.68,346.6a.62.62,0,1,0,.48,1.14l64.69-27.43L43.2,370a.62.62,0,1,0,.88.88L93.8,321.1,67.36,386.32a.62.62,0,0,0,1.15.46l26.4-65.11v70.26a.62.62,0,1,0,1.24,0V321.55l27.47,64.81a.62.62,0,0,0,1.14-.48L97.33,321.18,147,370.83a.62.62,0,0,0,.88-.88l-49.8-49.73,65.22,26.45a.62.62,0,1,0,.46-1.15L98.7,319.12H169a.62.62,0,0,0,0-1.24Z" />
        <path fill="#a1a5b2" fillRule="evenodd" d="M396.55,396.73c37.68,0,68.27,2.75,68.27,6.14S434.23,409,396.55,409s-68.27-2.75-68.27-6.14,30.59-6.14,68.27-6.14Z" />
        <path fill="#adafc5" fillRule="evenodd" d="M94.72,397.74c37.68,0,68.27,2.75,68.27,6.14S132.4,410,94.72,410s-68.27-2.75-68.27-6.14,30.59-6.14,68.27-6.14Z" />
        <path fill="#37353e" fillRule="evenodd" d="M405.28,228.45a87.72,87.72,0,1,1-87.72,87.72,87.76,87.76,0,0,1,87.72-87.72Zm0,16.72a71,71,0,1,1-71,71,71,71,0,0,1,71-71Z" />
        <path fill="#37353e" fillRule="evenodd" d="M94.72,228.45A87.72,87.72,0,1,1,7,316.17a87.76,87.76,0,0,1,87.72-87.72Zm0,16.72a71,71,0,1,1-71,71,71,71,0,0,1,71-71Z" />
        <path fill="#14438e" fillRule="evenodd" d="M410.05,316.64c-7.52-36.27-47.2-215-47.2-215L353.22,104l12.91,58.82H150.46v9.9H368.3l6,27.26L227,314.24,91.19,314a5,5,0,0,0,0,9.9l137.5.22.47.69.88-.68h.46v-.35L376.58,210.46,400.41,319C402,326.13,411.53,323.75,410.05,316.64Z" />
        <path fill="#848484" fillRule="evenodd" d="M240.08,278.52a45.63,45.63,0,1,1-45.63,45.63,45.65,45.65,0,0,1,45.63-45.63Z" />
        <path fill="#2560b7" fillRule="evenodd" d="M357.12,90a8.5,8.5,0,1,1-8.84,8.49A8.68,8.68,0,0,1,357.12,90Z" />
        <rect fill="#14438e" x="44.1" y="239.18" width="164.04" height="10.3" transform="translate(400.45 225.65) rotate(113.41)" />
        <path fill="#14438e" fillRule="evenodd" d="M124.76,132.33c-2.34,6.07-1.5,15,3.73,15.14,24.54.61,66.69.76,66.69-9.69C195.19,133.54,128.49,122.64,124.76,132.33Z" />
        <path fill="#ff8a29" fillRule="evenodd" d="M440.49,215.91H391.43l-17-72.68,36.19-9.09H455Z" />
    </svg>

}
export default BicycleIcon;