import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';


type BearerTextFieldProps = TextFieldProps & {
    validator?: (value: string) => string | undefined,
    onValidate?: (v: boolean) => void
};

const BearerStandardTextField: React.FC<BearerTextFieldProps> = ({ validator, onValidate, ...props }) => {
    const [touched, setTouched] = React.useState(false);
    const [error, setError] = React.useState({ value: '', error: false, helperText: '' });


    React.useEffect(() => {
        if (validator && props.value && touched) {
            const value = props.value as string;
            if (error.value !== value) {
                const helperText = validator(value);
                if (helperText) {
                    setError({ value, error: true, helperText });
                } else {
                    if (error.error) {
                        setError({ value, error: false, helperText: '' });
                    }
                }
            }
        }
        if (validator && onValidate) {
            onValidate(!validator(props.value as string));

        }
    }, [validator, onValidate, props.value, touched, error, setError])

    const handleFocus = () => {
        setTouched(true);
    }
    return (
        <TextField
            onFocus={handleFocus}
            variant="standard"
            size="small"
            {...props}
            error={error.error}
            helperText={error.helperText}
        />
    );
}
export default BearerStandardTextField;