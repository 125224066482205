export function validateName(value: string) {
  if (!(value.length >= 2)) return "Minimum 2 letters (English Only)";
  if (!value.match(/^[a-zA-Z\s]*$/)) return "Minimum 2 letters (English Only)";
}
export function validateOnlyMoreThanTwoLetter(value: string) {
  if (!(value.trim().length >= 2)) return "Minimum 2 letters (English Only)";
}
export function validateRegistrationNumber(value: string) {
  if (!value.match(/^[A-Z0-9]*$/))
    return "Must contain English Letter(s) and/or Number(s)";
  if (!value.trim()) return "Must contain English Letter(s) and/or Number(s)";
}

export function validateRegistrationNumberAndEnglsih(value: string) {
  if (!value.match(/^[0-9a-zA-Z]*$/))
    return "Must contain English Letter(s) and/or Number(s)";
  if (!(value.trim().length >= 2)) return "Minimum 2 letters (English Letter(s) and Number(s)";
}

export function validateStreetNumber(value: string) {
  if (!value.match(/^[0-9a-zA-Z]*$|^\d{1,5}\-\d{1,5}$/))
    return "Only English Letter(s), Number(s) and Number Ranges (i.e.: 13-36) are allowed";
  if (!value.trim())
    return "Only English Letter(s), Number(s) and Number Ranges (i.e.: 13-36) are allowed";
}
export function validateGeneralText(value: string) {
  if (!value.trim()) return "Minimum 1 character";
}

export function validatePostCode(value: string) {
  if (!(value.length === 4)) return "Please enter a 4 digit Post Code";
  if (!value.match(/^[0-9]/)) return "Please enter a 4 digit Post Code";
}
export function validateEmail(value: string) {
  if (
    !value.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    )
  )
    return "Email address is not valid!";
}
export function validatePassword(value: string) {
  if (
    !value.match(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$/
    )
  )
    return "Password must be 8 characters min At least 1 Uppercase, 1 Lowercase, 1 Number and 1 Special character";
}
export function validateMobileNumber(value: string) {
  if (!(value.length === 10)) return "10 Digits (Beginning with 04)";
  if (!value.match(/^[0-9]/)) return "10 Digits (Beginning with 04)";
  if (!(value.slice(0, 2) === "04")) return "10 Digits (Beginning with 04)";
}
export function validatePhone(value: string) {
  if (value.length < 12) return "10 Digits (Beginning with a +61)";
}
export function validateCapacity(value: string) {
  if (!(value.length >= 1)) return "Minimum 1 digit number";
  if (!value.match(/^[0-9]/)) return "Minimum 1 digit number";
}
export function validateABN(value: string) {
  const weights = [10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19],
    abn = value.replace(/[^\d]/, "");
  let result = false;
  if (abn.length === 11) {
    let sum = 0,
      weight;
    for (let index = 0; index <= weights.length - 1; index++) {
      weight = weights[index];
      const digit = Number(abn[index]) - (index ? 0 : 1);
      sum += weight * digit;
    }
    result = sum % 89 === 0;
  }
  if (!result) return "ABN must be a 11 digit number";
}
