
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        common: {
            black: "#000",
            white: "#fff",
        },
        primary: {
            main: '#009eff',
            light: '#63c3ff',
            contrastText: '#fff',
        },
        error: {
            main: '#ff2c2c',
            dark: '#912929',
        },
        success: {
            main: '#17d683',
            dark: '#299184',
        },
        grey: {
            '400': '#cdcdcd',
            '500': '#999999',
            '600': '#707070',
        },
    },
    typography: {
        fontFamily: [
            'Tahoma',
        ].join(','),
        fontSize: 13,
    },

})
export default theme;