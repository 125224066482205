import React from "react";
import BearerTextField from "../../../components/fields/BearerTextField";
import { validateGeneralText, validatePostCode, validateStreetNumber } from "../../../components/validation/FieldsValidation";
import { StatesEnumValues, useAppState } from "../../../states/app-state";
import { observer, Observer } from "mobx-react-lite";
import { Grid, MenuItem, TextField } from "@mui/material";

const ManualPersonalAddress: React.FC = observer(() => {
    const appState = useAppState();
    const { personalDetails } = appState.registration;
    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <Observer>{() => (
                    <BearerTextField
                        label="Apt#"
                        inputProps={{
                            readOnly: !personalDetails.checked,
                        }}
                        value={personalDetails.aptNumber}
                        onChange={(e: any) => personalDetails.setAptNumber(e.target.value)}
                    />
                )}</Observer>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Observer>{() => (
                    <BearerTextField
                        label="Unit#"
                        inputProps={{
                            readOnly: !personalDetails.checked,
                        }}
                        value={personalDetails.unitNumber}
                        onChange={(e: any) => personalDetails.setUnitNumber(e.target.value)}
                    />
                )}</Observer>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Observer>{() => (
                    <BearerTextField
                        label="Street#"
                        inputProps={{
                            readOnly: !personalDetails.checked,
                        }}
                        value={personalDetails.streetNumber}
                        onChange={(e: any) => personalDetails.setStreetNumber(e.target.value)}
                        onValidate={(v: any) => personalDetails.setStreetNumberValid(v)}
                        validator={validateStreetNumber}
                    />
                )}</Observer>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Observer>{() => (
                    <BearerTextField
                        label="Street Name"
                        inputProps={{
                            readOnly: !personalDetails.checked,
                        }}
                        value={personalDetails.streetName}
                        onChange={(e: any) => personalDetails.setStreetName(e.target.value)}
                        onValidate={(v: any) => personalDetails.setStreetNameValid(v)}
                        validator={validateGeneralText}
                    />
                )}</Observer>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Observer>{() => (
                    <BearerTextField
                        label={personalDetails.checked ? "Street Type" : ""}
                        inputProps={{
                            readOnly: !personalDetails.checked,
                        }}
                        value={personalDetails.streetType}
                        onChange={(e: any) => personalDetails.setStreetType(e.target.value)}
                        onValidate={(v: any) => personalDetails.setStreetTypeValid(v)}
                        validator={validateGeneralText}
                    />
                )}</Observer>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Observer>{() => (
                    <BearerTextField
                        label="City/Area"
                        inputProps={{
                            readOnly: !personalDetails.checked,
                        }}
                        value={personalDetails.city}
                        onChange={(e: any) => personalDetails.setCity(e.target.value)}
                        onValidate={(v: any) => personalDetails.setCityValid(v)}
                        validator={validateGeneralText}
                    />
                )}</Observer>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Observer>{() => (
                    <TextField
                        select
                        label="State"
                        value={personalDetails.state}
                        onChange={(e: any) => personalDetails.setState(e.target.value)}
                        variant="filled"
                        fullWidth
                        size="small"
                    >
                        {StatesEnumValues.map((state, index) => (
                            <MenuItem key={index} value={state}>
                                {state}
                            </MenuItem>
                        ))}
                    </TextField>
                )}</Observer>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Observer>{() => (
                    <BearerTextField
                        label="Post Code"
                        inputProps={{
                            readOnly: !personalDetails.checked,
                        }}
                        value={personalDetails.postCode}
                        onChange={(e: any) => personalDetails.setPosteCode(e.target.value)}
                        onValidate={(v: any) => personalDetails.setPostCodeValid(v)}
                        validator={validatePostCode}
                    />
                )}</Observer>
            </Grid>
        </Grid>

    )
})
export default ManualPersonalAddress;