import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import React from "react";
import { SuccessEnum, useAppState } from "../states/app-state";

const SuccessMessages: React.FC = observer(() => {
  const appState = useAppState();

  const { successMessage } = appState;
  if (!successMessage) {
    return null;
  }
  return (
    <>
      {successMessage.successCode === SuccessEnum.Success200 && <Success200 />}
      {successMessage.successCode === SuccessEnum.Success201 && <Success201 />}
      {successMessage.successCode === SuccessEnum.LogOut && <LogOut />}
    </>
  );
});
export default SuccessMessages;

const Success200: React.FC = observer(() => {
  const appState = useAppState();
  const { successMessage } = appState;
  if (!successMessage) {
    return null;
  }

  React.useEffect(() => {
    appState.getOnboardingRemoteConfigParameters();
  }, [appState]);

  const landingUrl = appState.onboardingRemoteConfigParameters?.landingpageUrl;

  const clickHandler = () => {
    window.open(landingUrl, "_parent");
  };
  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
          <Typography>{successMessage?.message}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ alignSelf: "center" }}>
        <Button variant="contained" onClick={clickHandler}>
          ᵔᴥᵔ
        </Button>
      </DialogActions>
    </Dialog>
  );
});

const Success201: React.FC = observer(() => {
  const appState = useAppState();
  const { successMessage } = appState;
  if (!successMessage) {
    return null;
  }

  const handleClick = () => {
    appState.setSuccessMessage(undefined);
    appState.registration.setPersonalDetailsStep();
  };

  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>{successMessage?.message}</DialogContentText>
      </DialogContent>
      <Button onClick={handleClick}>Ok</Button>
    </Dialog>
  );
});

const LogOut: React.FC = observer(() => {
  const appState = useAppState();
  const urlToGo =
    appState.remoteConfigBaseUrl !== null
      ? appState.remoteConfigBaseUrl.rider_onboarding_url
      : "";
  const { successMessage } = appState;
  if (!successMessage) {
    return null;
  }

  const handleClick = () => {
    appState.setSuccessMessage(undefined);
    appState.login.logout();
    window.location.href = urlToGo;
  };

  return (
    <Dialog open={true} className="sucess-dialog-dilog">
      <DialogContent>
        <DialogContentText>
          <br />
          ِ✅ Excellent job! Your contact details have been successfully saved.
          For added security, we're guiding you back to the login page. Please
          enter your email and password to continue the registration process.
          <br />
        </DialogContentText>
      </DialogContent>
      <Button onClick={handleClick}>Ok</Button>
    </Dialog>
  );
});
