import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormControl,
  RadioGroup,
  Tooltip,
  Radio,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import { observer, Observer } from "mobx-react-lite";
import DisabledClickLoading from "../../../components/DisabledClickLoading";
import { useAppState } from "../../../states/app-state";
import LogoutButton from "../../login/LogoutButton";
import { log } from "../../../components/error/error";
import WhatsAppFeedBack from "../../../components/common/WhatsAppFeedBack";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import BearerTextField from "../../../components/fields/BearerTextField";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { validateRegistrationNumberAndEnglsih } from "../../../components/validation/FieldsValidation";

import IdVerificationPoliceCheckCertificateFile from "./IdVerificationPoliceCheckCertificateFile";
import IdVerificationRightToWorkCertificateFile from "./IdVerificationRightToWorkCertificateFile";



const IdVerification: React.FC = observer(() => {
  const appState = useAppState();
  const { idVerification } = appState.registration;
  const [getNccLinkLoading, setGetNccLinkLoading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [nccRadio, setRadioNcc] = React.useState("newNcc");


  const nextHandler = async () => {
    window.scrollTo(0, 0);
    try {
      setLoading(true);
      await idVerification.onboardingNccNextButton();
      setLoading(false);
    } catch (err: any) {
      log(err);
      setLoading(false);
    }
  };


  const nextNewNccHandler = async () => {
    window.scrollTo(0, 0);
    try {
      setLoading(true);
      await idVerification.onboardingSetNccDataNextButton();
      setLoading(false);
    } catch (err: any) {
      log(err);
      setLoading(false);
    }
  };

  const getNccLink = async () => {
    try {
      setGetNccLinkLoading(true);
      await idVerification.onboardingGetNccLink();
      window.open(idVerification.url, "_parent");
      setGetNccLinkLoading(false);
    } catch (err: any) {
      log(err);
      setGetNccLinkLoading(false);
    }
  };
  const changeHandler = () => {
    idVerification.setChecked(!idVerification.checked);
    idVerification.setShowNccBtn(true);
  };

  React.useEffect(() => {
    idVerification.responseOfNCC();

  });

  React.useEffect(() => {
    appState.getOnboardingRemoteConfigParameters();
  }, [appState]);


  const handleNccRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRadioNcc((event.target as HTMLInputElement).value);
  };


  return (
    <div className="ncc-id-verification">
      <div style={{ marginBottom: "10px" }}>
        <LogoutButton />
      </div>
      <Typography>
        To proceed with your Bearer application, you have two options for the required National Crime Check (NCC) Background and Right to Work Checks:
      </Typography>
      <div>
        <ul>
          <li style={{ marginBottom: 15 }}><span style={{ fontWeight: "Bold" }}>Start a New Check:</span>
            <br />
            You can initiate a new NCC check directly. Please note, this option may incur a fee and typically takes 2-3 business days to process and receive the results.</li>
          <li><span style={{ fontWeight: "Bold" }}>Upload Recent Checks:</span>
            <br />
            Alternatively, if you already have completed NCC checks within the last 60 days, you can upload these reports for a quicker verification process, allowing your Bearer application to be processed faster.</li>
        </ul>
      </div>
      <Typography>
        Choose the option that best suits your needs and timeline.
      </Typography>

      <FormControl className="ncc-radio">
        <RadioGroup value={nccRadio} name="radio-buttons-group" onChange={handleNccRadioChange}>
          <FormControlLabel value="newNcc" control={<Radio />} label="Start a New NCC Check (Fees may apply)" />
          <FormControlLabel value="haveNcc" control={<Radio />} label="Upload an Existing NCC Report (Longer processing time)" disabled={idVerification.isNewNccRadioDisabled} />
        </RadioGroup>
      </FormControl>

      {appState.onboardingRemoteConfigParameters && <WhatsAppFeedBack />}


      {nccRadio === "newNcc" &&
        <>
          <Typography>
            Bearer partners with “National Crime Check Pty Ltd” for an easy and
            secure online solution for Identity Verification, Right to Work in
            Australia and Criminal History Check.
            <br />
            Providing false or inaccurate details might cause delays or even
            unsuccessful check results. By either of having unsuccessful check
            outcomes or incomplete checks, you will not be able to complete the
            onboarding process as a Bearer service provider and delays for assessing
            your application could be expected.
          </Typography>
          <FormGroup sx={{ marginTop: "20px" }}>
            <Observer>
              {() => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={idVerification.checked}
                        onChange={changeHandler}
                        disabled={idVerification.checked ? true : false}
                      />
                    }
                    label={
                      <>
                        <Typography>
                          I hereby give the permission to Bearer to:
                        </Typography>
                        <Typography>
                          a. Send my basic personal information to NCC™ and InstaID™
                          for ID Verification, Right-to Work and Criminal History
                          Check purposes.
                        </Typography>
                        <Typography>
                          b. Run an assessment to decide about my eligibility to
                          work as ‘Bearer Service Provider’ based on the feedback
                          provided by Bearer’s legal partners.
                        </Typography>
                      </>
                    }
                  />
                );
              }}
            </Observer>
          </FormGroup>

          <Grid container spacing={2} sx={{ marginTop: "10px" }}>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Observer>
                {() => {
                  return (
                    <Typography sx={{ textAlign: "justify" }}>
                      {idVerification.message}
                    </Typography>
                  );
                }}
              </Observer>
            </Grid>
            <Grid item xs={12} sx={{ textAlign: "center" }}>
              <Observer>
                {() => {
                  return (
                    <>
                      <Button
                        sx={{ backgroundColor: "#0D47A1", color: "#fff" }}
                        variant="contained"
                        onClick={getNccLink}
                        disabled={!idVerification.showNccBtn}
                      >
                        Open NCC
                      </Button>
                      {getNccLinkLoading && <DisabledClickLoading />}
                    </>
                  );
                }}
              </Observer>
            </Grid>
            <Grid item xs={12} textAlign="center" sx={{ marginTop: 3 }}>
              <Observer>
                {() => {
                  const valid = idVerification.nextBtn;
                  return (
                    <>
                      <Button
                        variant="contained"
                        disabled={!valid}
                        onClick={nextHandler}
                      >
                        Next
                      </Button>
                      {loading && <DisabledClickLoading />}
                    </>
                  );
                }}
              </Observer>
            </Grid>
          </Grid>
        </>
      }
      {nccRadio === "haveNcc" &&
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12}>
            <Typography textAlign="justify">
              If you have a recent Background and Right to Work report, make sure it:
            </Typography>
            <ul>
              <li> Was issued by National Crime Check (NCC).</li>
              <li> Was issued within the last 60 days.</li>
              <li> Matches the exact name used in this application form.</li>
            </ul>

          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" textAlign="justify">
              Police Check Certificate:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Observer>
              {() => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    minDate={
                      new Date(
                        new Date().getFullYear(),
                        new Date().getMonth() - 2,
                        new Date().getDate()
                      )
                    }
                    maxDate={new Date()}
                    openTo="year"
                    views={["year", "month", "day"]}
                    value={idVerification.policeCheckIssueDate}
                    onChange={(newValue: any) => {
                      if (newValue) {
                        try {
                          let myDate = new Date(newValue);
                          let sortedDate = myDate.toString().split(/\+|-/)[0];
                          let finalDate = new Date(sortedDate)
                            .toISOString()
                            .split("T")[0];
                          idVerification.setPoliceCheckIssueDate(finalDate)
                        } catch (_) { }
                      }
                    }}
                    inputFormat="yyyy/MM/dd"
                    mask="____/__/__"
                    label="Issue Date"
                    renderInput={(params: any) => (
                      <BearerTextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            </Observer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Tooltip
              title="Enter the two verification codes listed at the top-left of the check certificate. These fields are not case sensitive."
              placement="top-start"
            >
              <div>
                <Observer>
                  {() => (
                    <BearerTextField
                      label="Code 1"
                      value={idVerification.policeCheckFirstCode}
                      onChange={(e: any) => idVerification.setPoliceCheckFirstCode(e.target.value.toUpperCase())}
                      onValidate={(v: any) => idVerification.setPoliceCheckFirstCodeValid(v)}
                      validator={validateRegistrationNumberAndEnglsih}
                    />
                  )}
                </Observer>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Tooltip
              title="Enter the two verification codes listed at the top-left of the check certificate. These fields are not case sensitive."
              placement="top-start"
            >
              <div>
                <Observer>
                  {() => (
                    <BearerTextField
                      label="Code 2"
                      value={idVerification.policeCheckSecondCode}
                      onChange={(e: any) => idVerification.setPoliceCheckSecondCode(e.target.value.toUpperCase())}
                      onValidate={(v: any) => idVerification.setPoliceCheckSecondCodeValid(v)}
                      validator={validateRegistrationNumberAndEnglsih}
                    />
                  )}
                </Observer>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className="id-verification-file-upload">
              <Grid item xs={12} sm={2}>
                <Typography>Certificate:</Typography>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Observer>{() => <IdVerificationPoliceCheckCertificateFile />}</Observer>
              </Grid>
            </div>
          </Grid>

          {/* *****************  Right to Work Certificate ************* */}
          <Grid item xs={12} style={{ marginTop: 40 }}>
            <Typography variant="h6" textAlign="justify">
              Right to Work Certificate:
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Observer>
              {() => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    minDate={
                      new Date(
                        new Date().getFullYear(),
                        new Date().getMonth() - 2,
                        new Date().getDate()
                      )
                    }
                    maxDate={new Date()}
                    openTo="year"
                    views={["year", "month", "day"]}
                    value={idVerification.rightToWorkDate}
                    onChange={(newValue: any) => {
                      if (newValue) {
                        try {
                          let myDate = new Date(newValue);
                          let sortedDate = myDate.toString().split(/\+|-/)[0];
                          let finalDate = new Date(sortedDate)
                            .toISOString()
                            .split("T")[0];
                          idVerification.setRightToWorkDate(finalDate);
                        } catch (_) { }
                      }
                    }}
                    inputFormat="yyyy/MM/dd"
                    mask="____/__/__"
                    label="Issue Date"
                    renderInput={(params: any) => (
                      <BearerTextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              )}
            </Observer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Tooltip
              title="Enter the two verification codes listed at the top-left of the check certificate. These fields are not case sensitive."
              placement="top-start"
            >
              <div>
                <Observer>
                  {() => (
                    <BearerTextField
                      label="Code 1"
                      value={idVerification.rightToWorkFirstCode}
                      onChange={(e: any) => idVerification.setRightToWorkFirstCode(e.target.value.toUpperCase())}
                      onValidate={(v: any) => idVerification.setRightToWorkFirstCodeValid(v)}
                      validator={validateRegistrationNumberAndEnglsih}
                    />
                  )}
                </Observer>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Tooltip
              title="Enter the two verification codes listed at the top-left of the check certificate. These fields are not case sensitive."
              placement="top-start"
            >
              <div>
                <Observer>
                  {() => (
                    <BearerTextField
                      label="Code 2"
                      value={idVerification.rightToWorkSecondCode}
                      onChange={(e: any) => idVerification.setRightToWorkSecondCode(e.target.value.toUpperCase())}
                      onValidate={(v: any) => idVerification.setRightToWorkSecondCodeValid(v)}
                      validator={validateRegistrationNumberAndEnglsih}
                    />
                  )}
                </Observer>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={12}>
            <div className="id-verification-file-upload">
              <Grid item xs={12} sm={2}>
                <Typography>Certificate:</Typography>
              </Grid>
              <Grid item xs={12} sm={10}>
                <Observer>{() => <IdVerificationRightToWorkCertificateFile />}</Observer>
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} textAlign="center" sx={{ marginTop: 3 }}>
            <Observer>
              {() => {


                const isPoliceCheckDateValid =
                  idVerification.policeCheckIssueDate?.length === 10
                    ? true
                    : false;

                const isRightToWorkDateValid =
                  idVerification.rightToWorkDate?.length === 10
                    ? true
                    : false;


                const isPoliceCheckFileSelected =
                  idVerification.idVerificationPoliceCheckCertificateFile === ""
                    ? false
                    : true;

                const isRightToWorkFileSelected =
                  idVerification.idVerificationRightToWorkCertificateFile === ""
                    ? false
                    : true;



                const valid = isPoliceCheckDateValid
                  && isRightToWorkDateValid
                  && isPoliceCheckFileSelected
                  && isRightToWorkFileSelected
                  && !idVerification.isNewNccRadioDisabled
                  && idVerification.policeCheckFirstCodeValid
                  && idVerification.policeCheckSecondCodeValid
                  && idVerification.rightToWorkFirstCodeValid
                  && idVerification.rightToWorkSecondCodeValid
                return (
                  <>
                    <Button
                      variant="contained"
                      type="submit"
                      onClick={nextNewNccHandler}
                      disabled={!valid}
                    >

                      Next
                    </Button>
                    {loading && <DisabledClickLoading />}
                  </>
                );
              }}
            </Observer>
          </Grid>
        </Grid>
      }


    </div >

  );
});
export default IdVerification;
