import React, { useEffect } from "react";
import {
  Button,
  Grid,
  MenuItem,
  TextField,
  Typography,
  Tooltip,
} from "@mui/material";
import BearerTextField from "../../components/fields/BearerTextField";
import RadioButton from "../../components/radioButton/RadioButton";
import {
  MotorbikeTypeEnum,
  OwnEnum,
  StatesEnumValues,
  useAppState,
} from "../../states/app-state";
import { observer, Observer } from "mobx-react-lite";
import {
  validateCapacity,
  validateGeneralText,
  validateRegistrationNumber,
} from "../../components/validation/FieldsValidation";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import RegoCertificate from "./RegoCertificate";
import { fetchDownloadUrl } from "../../api/bearer-api";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import InfoIcon from "@mui/icons-material/Info";
import "../../styles.css";

const MotorbikeDetails: React.FC = observer(() => {
  const [insuranceDetailsOpen, setInsuranceDetailsOpen] = React.useState(false);
  const [attachedFile, setAttachedFile] = React.useState("");
  const handleInsuranceDetailsOpen = () => {
    setInsuranceDetailsOpen(true);
    appState.registration.modesOfTransport.setActivated(true);
  };
  const [loading, setLoading] = React.useState(false);
  const appState = useAppState();
  const motorbikeDetails = appState.registration.modesOfTransport;

  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth();
  const yyyy = today.getFullYear();

  useEffect(() => {
    if (motorbikeDetails.regoCertificateUrl !== "") {
      downloadPhotoUrl();
    }
  }, []);

  const downloadPhotoUrl = () => {
    const image =
      motorbikeDetails.regoCertificateUrl !== ""
        ? motorbikeDetails.regoCertificateUrl
        : "";

    if (
      image &&
      motorbikeDetails.state !== "" &&
      motorbikeDetails.engineCapacity !== "" &&
      motorbikeDetails.registrationNumber
    ) {
      setLoading(true);
      (async () => {
        const result = await fetchDownloadUrl(image);
        if (result) {
          setAttachedFile(result);
          setLoading(false);
        }
      })();
    }
  };

  return (
    <>
      <Typography>Do you own this motorbike?</Typography>
      <Observer>
        {() => (
          <RadioButton
            label="Yes"
            value={OwnEnum.Yes}
            checked={motorbikeDetails.own === OwnEnum.Yes}
            onClick={() => motorbikeDetails.setOwn(OwnEnum.Yes)}
          />
        )}
      </Observer>
      <Observer>
        {() => (
          <>
            <RadioButton
              label="No"
              value={OwnEnum.No}
              checked={motorbikeDetails.own === OwnEnum.No}
              onClick={() => motorbikeDetails.setOwn(OwnEnum.No)}
            />
            {motorbikeDetails.own === OwnEnum.No && (
              <Typography variant="caption" sx={{ color: "red" }}>
                You must be the owner to add a motorbike
              </Typography>
            )}
          </>
        )}
      </Observer>

      <Typography>Type:</Typography>
      <Observer>
        {() => (
          <RadioButton
            label="Petrol"
            value={MotorbikeTypeEnum.Petrol}
            checked={
              motorbikeDetails.motorbikeType === MotorbikeTypeEnum.Petrol
            }
            onClick={() =>
              motorbikeDetails.setMotorbikeType(MotorbikeTypeEnum.Petrol)
            }
          />
        )}
      </Observer>
      <Observer>
        {() => (
          <RadioButton
            label="Electric"
            value={MotorbikeTypeEnum.Electric}
            checked={
              motorbikeDetails.motorbikeType === MotorbikeTypeEnum.Electric
            }
            onClick={() =>
              motorbikeDetails.setMotorbikeType(MotorbikeTypeEnum.Electric)
            }
          />
        )}
      </Observer>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <Observer>
            {() => (
              <BearerTextField
                label={`${motorbikeDetails.motorbikeType === MotorbikeTypeEnum.Petrol
                    ? "Engine Capacity(cc)"
                    : "Engine Power(W)"
                  }`}
                value={motorbikeDetails.engineCapacity}
                onChange={(e: any) =>
                  motorbikeDetails.setEngineCapacity(
                    e.target.value.toLocaleUpperCase()
                  )
                }
                onValidate={(v: any) =>
                  motorbikeDetails.setEngineCapacityValid(v)
                }
                validator={validateCapacity}
              />
            )}
          </Observer>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Observer>
            {() => (
              <BearerTextField
                label="Registration Number"
                value={motorbikeDetails.registrationNumber}
                onChange={(e: any) =>
                  motorbikeDetails.setRegistrationNumber(
                    e.target.value.toLocaleUpperCase()
                  )
                }
                onValidate={(v: any) =>
                  motorbikeDetails.setRegistrationNumberValid(v)
                }
                validator={validateRegistrationNumber}
              />
            )}
          </Observer>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Observer>
            {() => (
              <BearerTextField
                label="Make"
                value={motorbikeDetails.make}
                onChange={(e: any) =>
                  motorbikeDetails.setMake(e.target.value.toLocaleUpperCase())
                }
                onValidate={(v: any) => motorbikeDetails.setMakeValid(v)}
                validator={validateGeneralText}
              />
            )}
          </Observer>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Observer>
            {() => (
              <BearerTextField
                label="Model"
                value={motorbikeDetails.model}
                onChange={(e: any) =>
                  motorbikeDetails.setModel(e.target.value.toLocaleUpperCase())
                }
                onValidate={(v: any) => motorbikeDetails.setModelValid(v)}
                validator={validateGeneralText}
              />
            )}
          </Observer>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Observer>
            {() => (
              <TextField
                select
                label="State"
                value={motorbikeDetails.state}
                onChange={(e: any) =>
                  motorbikeDetails.setState(e.target.value.toLocaleUpperCase())
                }
                variant="filled"
                fullWidth
                size="small"
              >
                {StatesEnumValues.map((state, index) => (
                  <MenuItem key={index} value={state}>
                    {state}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Observer>
        </Grid>
        <Grid item xs={12} sm={4}></Grid>
        <Grid item xs={12} sm={6} className="observer-datepicker">
          <Typography>Rego Expire Date:</Typography>
          <Observer>
            {() => (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  minDate={new Date(yyyy, mm + 2, dd + 1)}
                  openTo="year"
                  views={["year", "month", "day"]}
                  value={motorbikeDetails.regoValidityDate}
                  onChange={(newValue: any) => {
                    if (newValue) {
                      try {
                        let myDate = new Date(newValue);
                        let sortedDate = myDate.toString().split(/\+|-/)[0];
                        let finalDate = new Date(sortedDate)
                          .toISOString()
                          .split("T")[0];
                        motorbikeDetails.setRegoValidityDate(finalDate);
                      } catch (_) { }
                    }
                  }}
                  inputFormat="yyyy/MM/dd"
                  mask="____/__/__"
                  renderInput={(params) => (
                    <BearerTextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        readOnly: true,
                      }}
                    />
                  )}
                />
                <Tooltip
                  title="Vehicle Registration must have at least 2 months validity"
                  placement="bottom-end"
                  className="tooltip"
                >
                  <InfoIcon />
                </Tooltip>
              </LocalizationProvider>
            )}
          </Observer>
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid
          item
          xs={12}
          direction="row"
          sx={{ display: "flex", alignItems: "center", gap: 1 }}
        >
          <Typography>Attach File:</Typography>
          <Observer>{() => <RegoCertificate />}</Observer>
          {attachedFile !== "" &&
            !motorbikeDetails.isRegoCertificateUrlHasChanged ? (
            <Typography>
              <a id="link" href={attachedFile} target="_blank">
                View Attached File
              </a>
            </Typography>
          ) : (
            ""
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} textAlign="right">
        <Observer>
          {() => {
            const regoCer =
              motorbikeDetails.regoCertificateUrl === "" ? false : true;

            const isRegoValidityDate =
              motorbikeDetails.regoValidityDate?.length === 10 ? true : false;

            const valid =
              motorbikeDetails.engineCapacityValid &&
              motorbikeDetails.makeValid &&
              motorbikeDetails.modelValid &&
              motorbikeDetails.RegistrationNumberValid &&
              motorbikeDetails.motorbikeType &&
              motorbikeDetails.own === OwnEnum.Yes &&
              isRegoValidityDate &&
              regoCer;
            return (
              <>
                <Button
                  variant="contained"
                  onClick={handleInsuranceDetailsOpen}
                  disabled={!valid}
                >
                  next
                </Button>
                {loading && <DisabledClickLoading />}
              </>
            );
          }}
        </Observer>
      </Grid>
    </>
  );
});
export default MotorbikeDetails;
