import React, { useEffect } from "react";
import { Button, Grid, Typography, Tooltip } from "@mui/material";
import RadioButton from "../../components/radioButton/RadioButton";
import BearerTextField from "../../components/fields/BearerTextField";
import {
  InsurancePolicyType,
  InsurancePolicyTypeEnum,
  useAppState,
} from "../../states/app-state";
import { observer, Observer } from "mobx-react-lite";
import InsuranceFile from "./InsuranceFile";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import { validateGeneralText } from "../../components/validation/FieldsValidation";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { fetchDownloadUrl } from "../../api/bearer-api";
import InfoIcon from "@mui/icons-material/Info";
import "../../styles.css";
import { log } from "../../components/error/error";

const InsuranceDetails: React.FC = observer(() => {
  const appState = useAppState();
  const insuranceDetails = appState.registration.modesOfTransport;
  const [loading, setLoading] = React.useState(false);
  const [attachedFile, setAttachedFile] = React.useState("");

  const addHandler = async () => {
    try {
      setLoading(true);
      await appState.registration.modesOfTransport.addMotorbike();
      appState.registration.modesOfTransport.setOpen(false);
      appState.registration.modesOfTransport.setActivated(false);
      setLoading(false);
      appState.registration.modesOfTransport.setIsRegoCertificateUrlHasChanged(
        false
      );
      appState.registration.modesOfTransport.setIsInsuranceUrlHasChanged(false);
    } catch (err: any) {
      setLoading(false);
      log(err);
    }
  };

  useEffect(() => {
    if (insuranceDetails.ipcPolicyCertificateUrl !== "") {
      downloadPhotoUrl();
    }
  }, []);

  const editHandler = async () => {
    try {
      setLoading(true);
      await appState.registration.modesOfTransport.editMotorbike();
      appState.registration.modesOfTransport.setOpen(false);
      setLoading(false);
      insuranceDetails.setIsIpcPolicyCertificateUploaded(false);
      insuranceDetails.setIsRegoCertificateUploaded(false);
      appState.registration.modesOfTransport.setActivated(false);
      appState.registration.modesOfTransport.setIsRegoCertificateUrlHasChanged(
        false
      );
      appState.registration.modesOfTransport.setIsInsuranceUrlHasChanged(false);
    } catch (err: any) {
      setLoading(false);
      log(err);
    }
  };

  const downloadPhotoUrl = () => {
    const image =
      insuranceDetails.ipcPolicyCertificateUrl !== ""
        ? insuranceDetails.ipcPolicyCertificateUrl
        : "";

    if (
      image &&
      insuranceDetails.insurerCompany !== "" &&
      insuranceDetails.policyNumber !== ""
    ) {
      setLoading(true);
      (async () => {
        const result = await fetchDownloadUrl(image);
        if (result) {
          setAttachedFile(result);
          setLoading(false);
        }
      })();
    }
  };

  const doneHandler = () => {
    if (appState.registration.modesOfTransport.motorbikeId) {
      editHandler();
    } else {
      addHandler();
    }
  };

  const backHandler = () => {
    appState.registration.modesOfTransport.setActivated(false);
  };

  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth();
  const yyyy = today.getFullYear();

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12}>
        <Typography>Insurance Policy Type:</Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Observer>
          {() => (
            <RadioButton
              label={InsurancePolicyType(
                InsurancePolicyTypeEnum.ComprehensiveInsurance
              )}
              value={InsurancePolicyTypeEnum.ComprehensiveInsurance}
              checked={
                insuranceDetails.insurancePolicyType ===
                InsurancePolicyTypeEnum.ComprehensiveInsurance
              }
              onClick={() =>
                insuranceDetails.setInsurancePolicyType(
                  InsurancePolicyTypeEnum.ComprehensiveInsurance
                )
              }
            />
          )}
        </Observer>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Observer>
          {() => (
            <RadioButton
              label={InsurancePolicyType(
                InsurancePolicyTypeEnum.ThirdPartyPropertyDamage
              )}
              value={InsurancePolicyTypeEnum.ThirdPartyPropertyDamage}
              checked={
                insuranceDetails.insurancePolicyType ===
                InsurancePolicyTypeEnum.ThirdPartyPropertyDamage
              }
              onClick={() =>
                insuranceDetails.setInsurancePolicyType(
                  InsurancePolicyTypeEnum.ThirdPartyPropertyDamage
                )
              }
            />
          )}
        </Observer>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Observer>
          {() => (
            <BearerTextField
              label="Insurer Company"
              value={insuranceDetails.insurerCompany}
              onChange={(e: any) =>
                insuranceDetails.setInsurerCompany(
                  e.target.value.toLocaleUpperCase()
                )
              }
              onValidate={(v: any) =>
                insuranceDetails.setInsurerCompanyValid(v)
              }
              validator={validateGeneralText}
            />
          )}
        </Observer>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Observer>
          {() => (
            <BearerTextField
              label="Policy Number"
              value={insuranceDetails.policyNumber}
              onChange={(e: any) =>
                insuranceDetails.setPolicyNumber(
                  e.target.value.toLocaleUpperCase()
                )
              }
              onValidate={(v: any) => insuranceDetails.setPolicyNumberValid(v)}
              validator={validateGeneralText}
            />
          )}
        </Observer>
      </Grid>
      <Grid item xs={12}></Grid>
      <Grid item xs={12} sm={6} className="observer-datepicker">
        <Typography>Validity Date:</Typography>
        <Observer>
          {() => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                minDate={new Date(yyyy, mm + 2, dd - 1)}
                openTo="year"
                views={["year", "month", "day"]}
                value={insuranceDetails.validityDate}
                onChange={(newValue: any) => {
                  if (newValue) {
                    try {
                      let myDate = new Date(newValue);
                      let sortedDate = myDate.toString().split(/\+|-/)[0];
                      let finalDate = new Date(sortedDate)
                        .toISOString()
                        .split("T")[0];
                      insuranceDetails.setValidityDate(finalDate);
                    } catch (_) {}
                  }
                }}
                inputFormat="yyyy/MM/dd"
                mask="____/__/__"
                renderInput={(params) => (
                  <BearerTextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      readOnly: true,
                    }}
                  />
                )}
              />
              <Tooltip
                title="Your cover must have at least 2 months validity"
                placement="bottom-end"
                className="tooltip"
              >
                <InfoIcon />
              </Tooltip>
            </LocalizationProvider>
          )}
        </Observer>
      </Grid>
      <Grid item xs={12} sm={6}></Grid>
      <Grid item xs={12} sm={6}></Grid>
      <Grid
        item
        xs={12}
        direction="row"
        sx={{ display: "flex", alignItems: "center", gap: 1 }}
      >
        <Typography>Attach File:</Typography>
        <Observer>{() => <InsuranceFile />}</Observer>
        {attachedFile !== "" && !insuranceDetails.isInsuranceUrlHasChanged ? (
          <Typography>
            <a id="link" href={attachedFile} target="_blank">
              View Attached File
            </a>
          </Typography>
        ) : (
          ""
        )}
      </Grid>

      <Grid item xs={12} sm={6} textAlign="center">
        <Button variant="contained" onClick={backHandler}>
          Back
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} textAlign="center">
        <Observer>
          {() => {
            const isFileSelected =
              insuranceDetails.ipcPolicyCertificateUrl === "" ? false : true;
            const isValidDate =
              insuranceDetails.validityDate?.length === 10 ? true : false;
            const valid =
              insuranceDetails.insuranceComponyValid &&
              insuranceDetails.policyNumberValid &&
              insuranceDetails.insurancePolicyType &&
              isFileSelected &&
              isValidDate;

            return (
              <>
                <Button
                  variant="contained"
                  onClick={doneHandler}
                  disabled={!valid}
                >
                  Done
                </Button>
                {loading && <DisabledClickLoading />}
              </>
            );
          }}
        </Observer>
      </Grid>
    </Grid>
  );
});
export default InsuranceDetails;
