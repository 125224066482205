import React, { useEffect } from "react";
import { Button, FormHelperText, Grid, Typography } from "@mui/material";
import { observer, Observer } from "mobx-react-lite";
import NumberFormat from "react-number-format";
import DisabledClickLoading from "../../../components/DisabledClickLoading";
import BearerTextField from "../../../components/fields/BearerTextField";
import { validateMobileNumber } from "../../../components/validation/FieldsValidation";
import { useAppState } from "../../../states/app-state";
import { log } from "../../../components/error/error";

const ThirdStep: React.FC = observer(() => {
  const appState = useAppState();
  const { signUp } = appState.registration;
  const [loadingSendSMS, setLoadingSendSMS] = React.useState(false);
  const handleSendSMS = async () => {
    try {
      setLoadingSendSMS(true);
      await signUp.sendSMS();
      setLoadingSendSMS(false);
    } catch (err: any) {
      log(err);
      setLoadingSendSMS(false);
    }
  };
  const [error, setError] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const handleConfirmSMS = async () => {
    try {
      setError("");
      setLoading(true);
      await signUp.ConfirmSMS();
      setLoading(false);
    } catch (err: any) {
      log(err);
      setLoading(false);
      setError(err);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [counter, setCounter] = React.useState(60);
  React.useEffect(() => {
    if (signUp.timer) {
      const timer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
      if (counter === 0) {
        clearTimeout(timer);
        signUp.setTimer(false);
      }
    }
  }, [counter, signUp.timer, signUp]);

  return (
    <Grid container spacing={1} sx={{ margin: "8px 0" }}>
      <Grid item sm={6} xs={12}>
        <Observer>
          {() => (
            <BearerTextField
              sx={{ paddingBottom: "10px" }}
              label="Mobile Phone Number"
              type="tel"
              value={signUp.phoneNumber}
              onChange={(e: any) => signUp.setPhoneNumber(e.target.value)}
              validator={validateMobileNumber}
              onValidate={(v: any) => signUp.setPhonNumberValid(v)}
            />
          )}
        </Observer>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Observer>
          {() => (
            <>
              <NumberFormat
                style={{
                  border: "none",
                  height: 45,
                  backgroundColor: "#e6e6e6",
                  borderBottom: "1px solid black",
                  borderRadius: 2,
                  width: " -webkit-fill-available",
                }}
                format="######"
                value={signUp.token}
                onChange={(e: any) => signUp.setToken(e.target.value)}
                placeholder="SMS Code"
              />
              {error && (
                <FormHelperText error={!!error}>{error}</FormHelperText>
              )}
              {signUp.timer && <Typography>{counter} Sec.</Typography>}
            </>
          )}
        </Observer>
      </Grid>
      <Observer>
        {() => {
          const valid = signUp.phoneNumberValid && !signUp.timer;
          return (
            <>
              <Button
                variant="contained"
                sx={{ display: "block", margin: "20px auto 0 auto" }}
                onClick={handleSendSMS}
                disabled={!valid}
              >
                send Code
              </Button>
              {loadingSendSMS && <DisabledClickLoading />}
            </>
          );
        }}
      </Observer>
      <Observer>
        {() => {
          const valid = signUp.token;
          return (
            <>
              <Button
                variant="contained"
                sx={{ display: "block", margin: "20px auto 0 auto" }}
                onClick={handleConfirmSMS}
                disabled={!valid}
              >
                Verify Code
              </Button>
              {loading && <DisabledClickLoading />}
            </>
          );
        }}
      </Observer>
    </Grid>
  );
});
export default ThirdStep;
