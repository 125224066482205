import React from 'react';
import { SvgIconProps } from '@mui/material';

const WalkIcon: React.FC<SvgIconProps> = (props) => {
    return <svg id="Layer_1" xmlns="https://www.w3.org/2000/svg" viewBox="0 0 905 905" style={{ transform: 'translatey(25px)', width: '70px' }}>
        <defs>
            <clipPath id="clip-path">
                <path fill="none" clipRule="evenodd" d="M213.92,67.78c6.58,5.41,17,43.12,29.28,58.79,16.83,21.53,70.93,49.19,90,61.15,36.6,23,22.58,53.31,22.58,53.31s-158.31-16.93-174.09-18C169.63,222.26,28,112.1,28,112.1S47.15,85.29,70.43,58C82,44.49,92.54,42.28,92.54,42.28s20.27,42.16,37.84,53.05c27.31,16.92,14.93,20.1,73.38-1.23,8.84-3.22,3.59-31.74,10.17-26.32Z" />
            </clipPath>
            <clipPath id="clip-path-2">
                <path fill="none" clipRule="evenodd" d="M667.28,30.41C686.81,33.14,758.16,68.58,794.91,73c70.55,8.4,100.08,14.76,106.36,34.45,8.39,26.31,0,29.36,0,29.36L571.55,200.59s-9.18-6.73-10.63-42.55c-.72-17.8,0-63.81,0-63.81s43.53,17.14,63.82,13.18c31.53-6.16,24.62,4.59,53-50.78C682,48.26,658,29.11,667.28,30.41Z" />
            </clipPath>
        </defs>
        <path fill="#acb2bf" fillRule="evenodd" d="M560.92,241c2.91-28.7,42.08-21.9,170.18-32.71C819,200.91,910.22,220.91,901.28,241c-16.82,37.83-90.81,46.89-148.91,44.64-15.7-.61-195.9-.77-191.45-44.64Z" />
        <path fill="#acb2bf" fillRule="evenodd" d="M302.18,200.59S362,233.41,358.41,241s4.91,12.68-77.51,5.33-174.08-5.84-234-10.28c-46.28-3.44-86.55-50,23.67-51.43,46.51-.62,231.6,15.93,231.6,15.93Z" />
        <path fill="#ff8a29" fillRule="evenodd" d="M667.28,30.41C686.81,33.14,758.16,68.58,794.91,73c70.55,8.4,100.08,14.76,106.36,34.45,8.39,26.31,0,29.36,0,29.36L571.55,200.59s-9.18-6.73-10.63-42.55c-.72-17.8,0-63.81,0-63.81s43.53,17.14,63.82,13.18c31.53-6.16,24.62,4.59,53-50.78C682,48.26,658,29.11,667.28,30.41Z" />
        <path fill="none" fillRule="evenodd" d="M667.28,30.41C686.81,33.14,758.16,68.58,794.91,73c70.55,8.4,100.08,14.76,106.36,34.45,8.39,26.31,0,29.36,0,29.36L571.55,200.59s-9.18-6.73-10.63-42.55c-.72-17.8,0-63.81,0-63.81s43.53,17.14,63.82,13.18c31.53-6.16,24.62,4.59,53-50.78C682,48.26,658,29.11,667.28,30.41Z" />
        <path fill="#fff" d="M753.21,98.51a5.07,5.07,0,0,1-3.62-1.5c-2.84-2.84-2.55-9.48-1.08-26.36.13-1.44.26-3,.39-4.57-1.24,2.87-2.48,5.86-3.53,8.38C737,94.59,735.16,98.17,731.1,98.17a4.92,4.92,0,0,1-3.24-1.44c-2.22-2.41-2.1-7-.68-24.1.17-2,.37-4.41.54-6.76-1.27,2.94-2.55,6-3.63,8.6-8.38,20.12-10.2,23.7-14.27,23.7a5,5,0,0,1-3.28-1.47c-1.1-1.19-4-4.33-.74-44.07C700,43,684.62,16.44,684.62,9.14c0-4.1,3-7.25,8-8.43C699-.78,710,.73,713.34,7.38c1.71,3.41,2.1,11.32,2,19.63,9.27-21,13.6-21.55,15.27-21.77C737,4.39,746,3.92,749.91,9.64c4.16,6.05-.48,14.54-3.55,20.15-.48.89-.92,1.69-1.27,2.39-3.52,7-23,17.66-31.53,22-.78,9.89-1.33,20.63-1.39,28.2,1.59-3.59,3.28-7.67,4.65-11,8.38-20.11,10.2-23.69,14.26-23.69a4.92,4.92,0,0,1,3.24,1.44c2.22,2.41,2.1,7,.68,24.09-.17,2-.37,4.41-.55,6.77,1.27-2.94,2.55-6,3.63-8.61,8.38-20.11,10.2-23.69,14.27-23.69a5,5,0,0,1,3.27,1.45c2.16,2.36,2.06,6.56.7,22.13-.43,4.94-1.07,12.27-1.08,16.88,2.08-2.34,4.67-5.74,6.47-8.11,6-7.88,8.56-11.08,11.9-11.08a4.67,4.67,0,0,1,3.57,1.66c2.05,2.42,1.55,6.11.31,13.55-.12.75-.27,1.63-.41,2.55,4.05-4,9.9-10.48,14.79-16.35a3.93,3.93,0,0,1,6,5C779,98.17,775.25,98.17,773.64,98.17a4.67,4.67,0,0,1-3.57-1.66c-2.05-2.42-1.55-6.11-.31-13.55l.08-.5L768,84.87c-7.06,9.28-10.56,13.37-14.42,13.63ZM732.07,13c-2.86,2.27-9.27,16-14.91,30.35,10.16-5.68,19.49-11.86,20.89-14.68.39-.77.87-1.66,1.41-2.64,1.61-2.95,5.39-9.86,4-11.93C743.26,13.84,741.56,11.78,732.07,13ZM697.55,8c-3.17,0-4.86.88-5.11,1.23.1,2.94,6.8,16.11,14.47,29.63.81-12.95.81-25.16-.6-28-.48-1-3.15-2.51-7.38-2.82C698.44,8,698,8,697.55,8Zm34.71,4.81" />
        <path fill="#ff8a29" d="M213.92,67.78c6.58,5.41,17,43.12,29.28,58.79,16.83,21.53,70.93,49.19,90,61.15,36.6,23,22.58,53.31,22.58,53.31s-158.31-16.93-174.09-18C169.63,222.26,28,112.1,28,112.1S47.15,85.29,70.43,58C82,44.49,92.54,42.28,92.54,42.28s20.27,42.16,37.84,53.05c27.31,16.92,14.93,20.1,73.38-1.23,8.84-3.22,3.59-31.74,10.17-26.32Z" />
        <path fill="none" d="M213.92,67.78c6.58,5.41,17,43.12,29.28,58.79,16.83,21.53,70.93,49.19,90,61.15,36.6,23,22.58,53.31,22.58,53.31s-158.31-16.93-174.09-18C169.63,222.26,28,112.1,28,112.1S47.15,85.29,70.43,58C82,44.49,92.54,42.28,92.54,42.28s20.27,42.16,37.84,53.05c27.31,16.92,14.93,20.1,73.38-1.23,8.84-3.22,3.59-31.74,10.17-26.32Z" />
        <path fill="#fff" d="M270,191a3.75,3.75,0,0,1-2.77-1.3c-3.26-3-1.21-7.6,6.4-23,1.08-2.19,2.38-4.81,3.57-7.32-2,1.76-4.06,3.65-5.79,5.22C259.72,175.27,256.52,178,253,176.51a4.37,4.37,0,0,1-2.64-3.93c-.1-2.54,1.31-6,4-12.51,1.68-4,5-11.92,5.21-15.2-3.95.67-15.41,6.17-22.49,9.56-15.27,7.31-19.4,9.12-22.38,6.36a4.84,4.84,0,0,1-1.37-3.2c0-3.24,3.22-6.52,15.85-18.07,1.62-1.48,3.51-3.21,5.36-4.93-3.07,1.35-6.28,2.82-9,4-19.78,9-23.54,10.43-26.48,7.69a5,5,0,0,1-1.39-3.24c0-1.59,0-5.77,28.6-32-2.09-7.89-8.61-33.73-7.46-44.8.75-7.26,10.56-32.68,18.22-33,2,0,4.4,1.1,5,4.6,1.16,6.69-4.16,48.72-6.65,67.64,35.41-17.74,40.29-14.29,42-13.12,5.27,3.72,12.16,9.46,11.16,16.28-1.06,7.22-10.2,10.28-16.25,12.3-1,.32-1.82.61-2.55.88-7.35,2.76-28.92-2.72-38.08-5.29-7.44,6.86-15.29,14.51-20.48,20.05,3.69-1.59,7.86-3.49,11.2-5,19.48-8.9,23.55-10.39,26.48-7.69a4.85,4.85,0,0,1,1.37,3.2c0,3.25-3.22,6.53-15.86,18.07l-4.47,4.1,3.85-1.83c17.08-8.19,25-11.59,29.49-9.84a6,6,0,0,1,3.38,3.33c1.9,4.49-.81,11.65-5.16,22.06l-.11.26c1.67-1.49,3.37-3,4.81-4.35,12.26-11.17,16.84-15.33,20.71-11.76,3.26,3,1.22,7.59-6.4,23-1.36,2.75-3.07,6.21-4.47,9.25,5.71-4.24,14.09-11.3,21.27-17.78a3.85,3.85,0,0,1,5.17,5.71C280.93,186.8,273.41,191,270,191ZM241.49,111.2c11.4,2.77,22.6,4.61,25.56,3.48.81-.3,1.77-.62,2.82-1,3.2-1.07,10.7-3.58,11.08-6.11,0-.3.19-3-7.63-8.63C269.72,98.61,255.52,104.41,241.49,111.2Zm-7.12-64.58c-3.34,6-7.27,15-7.83,20.43-.53,5.08,1,14.8,3,24.14C231.63,74.46,233.68,56.59,234.37,46.62Z" />
        <g clipPath="url(#clip-path)">
            <path fill="#14438e" fillRule="evenodd" d="M385.84,218.63S215.18,223.25,164,187.05c-17.37-12.29-46.48-45-68.08-65.88-20.48-19.77-61.5-59.25-61.5-59.25L5.53,135.67,316,307.39l69.87-88.77Z" />
        </g>
        <g clipPath="url(#clip-path-2)">
            <path fill="#14438e" fillRule="evenodd" d="M922.54,115.5S780.73,151,688.55,158c-21.21,1.64-42.83-3.49-63.81,0-21.33,3.55-64.14,21.27-64.14,21.27v42.55L905,136.77l17.54-21.27Z" />
        </g>
    </svg >

}
export default WalkIcon;