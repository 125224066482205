import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import BearerTextField from "../../../components/fields/BearerTextField";
import {
  validateABN,
  validateGeneralText,
  validateOnlyMoreThanTwoLetter,
  validatePhone,
} from "../../../components/validation/FieldsValidation";
import { AddressType, useAppState } from "../../../states/app-state";
import { observer, Observer } from "mobx-react-lite";
import DisabledClickLoading from "../../../components/DisabledClickLoading";
import AutocompleteAddress from "../addressFields/AutocompleteAddress";
import ManualLegalAddress from "../addressFields/ManualLegalAddress";
import {
  extractCity,
  extractPostCode,
  extractState,
  extractStreetName,
  extractStreetNumber,
  extractUnitNumber,
} from "../addressFields/ExtractAddress";
import LogoutButton from "../../login/LogoutButton";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import PublicLiabilityInsuranceFile from "./PublicLiabilityInsuranceFile";
import WarningIcon from "@mui/icons-material/Warning";
import { log } from "../../../components/error/error";
import WhatsAppFeedBack from "../../../components/common/WhatsAppFeedBack";

const LegalDetails: React.FC = observer(() => {
  const appState = useAppState();
  const { legalDetails } = appState.registration;

  const handleFirstChange = () => {
    legalDetails.setFirstChecked(!legalDetails.firstChecked);
  };
  const handleSecondChange = () => {
    legalDetails.setSecondChecked(!legalDetails.secondChecked);
  };
  const handleThirdChange = () => {
    legalDetails.setThirdChecked(!legalDetails.thirdChecked);
  };
  const handleRiderRegisteredForGst = () => {
    legalDetails.setRiderRegisteredForGst(!legalDetails.riderRegisteredForGst);
  };
  const delivery_person_agreement =
    appState.onboardingRemoteConfigParameters?.rider_tc_url;

  const [loading, setLoading] = React.useState(false);
  const nextHandler = async (e: any) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    try {
      legalDetails.setAbnAndLegalNameError("");
      setLoading(true);
      await legalDetails.setLegalDetails();
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      log(err);
    }
  };

  const handleChange = () => {
    legalDetails.setChecked(!legalDetails.checked);
  };
  const backHandler = () => {
    appState.registration.setPersonalDetailsStep();
  };

  React.useEffect(() => {
    appState.getOnboardingRemoteConfigParameters();
  }, [appState]);

  const soleTraderUrl =
    appState.onboardingRemoteConfigParameters?.soleTraderUrl;

  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth();
  const yyyy = today.getFullYear();

  const handlePublicLiabilityInsuranceNotProvided = () => {
    legalDetails.setPublicLiabilityInsuranceNotProvided(
      !legalDetails.publicLiabilityInsuranceNotProvided
    );
  };

  return (
    <>
      {appState.onboardingRemoteConfigParameters && <WhatsAppFeedBack />}
      <div style={{ marginBottom: "10px" }}>
        <LogoutButton />
      </div>
      <form onSubmit={nextHandler}>
        <Typography>
          To become a Bearer, you need to be registered as a “Sole Trader” and
          hold a valid ABN. Please provide your legal details as currently
          registered with ASIC and/or other legal authorities.
        </Typography>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={6}>
            <Tooltip
              title="* Legal Name : A legal name is the name that appears on all official documents or legal papers. Your legal name can be different from your business name."
              placement="top-start"
            >
              <div>
                <Observer>
                  {() => (
                    <BearerTextField
                      label="Legal Name"
                      value={legalDetails.legalName}
                      onChange={(e: any) =>
                        legalDetails.setLegalName(e.target.value)
                      }
                      onValidate={(v: any) => legalDetails.setLegalNameValid(v)}
                      validator={validateOnlyMoreThanTwoLetter}
                    />
                  )}
                </Observer>
                <Observer>
                  {() => (
                    <p style={{ color: "red" }}>
                      {legalDetails.abnAndLegalNameError}
                    </p>
                  )}
                </Observer>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Tooltip
              title="*ABN : An ABN is a unique 11 digit number that identifies your business to the government and community."
              placement="top-start"
            >
              <div>
                <Observer>
                  {() => (
                    <BearerTextField
                      label="ABN No"
                      value={legalDetails.abnNumber}
                      onChange={(e: any) =>
                        legalDetails.setAbnNumber(
                          e.target.value.replace(/\s/g, "")
                        )
                      }
                      onValidate={(v: any) => legalDetails.setAbnNumberValid(v)}
                      validator={validateABN}
                    />
                  )}
                </Observer>
                <Observer>
                  {() => (
                    <p style={{ color: "red" }}>
                      {legalDetails.abnAndLegalNameError}
                    </p>
                  )}
                </Observer>
              </div>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <div style={{ position: "relative" }}>
              <Observer>
                {() => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={legalDetails.checked}
                        onChange={handleChange}
                      />
                    }
                    label="No match?"
                  />
                )}
              </Observer>
              <Tooltip
                style={{ position: "absolute", top: "8px" }}
                title="Start typing the address and then select it from the list e.g. “447 Collins” to return “447 Collins St, MELBOURNE VIC 3000”. If not listed, select 'No match?' and enter the address manually"
                placement="bottom"
                className="tooltip"
              >
                <InfoIcon />
              </Tooltip>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Observer>
              {() => (
                <>
                  {!legalDetails.checked && (
                    <AutocompleteAddress
                      onChange={(place: AddressType | null) => {
                        legalDetails.setPosteCode(extractPostCode(place));
                        legalDetails.setStreetName(extractStreetName(place));
                        legalDetails.setStreetNumber(
                          extractStreetNumber(place)
                        );
                        legalDetails.setCity(extractCity(place));
                        legalDetails.setState(extractState(place));
                        legalDetails.setUnitNumber(extractUnitNumber(place));
                      }}
                    />
                  )}
                  <ManualLegalAddress />
                </>
              )}
            </Observer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Observer>
              {() => (
                <BearerTextField
                  label="Phone#"
                  value={legalDetails.phone}
                  onChange={(e: any) => legalDetails.setPhone(e.target.value)}
                  onValidate={(v: any) => legalDetails.setPhoneValid(v)}
                  validator={validatePhone}
                />
              )}
            </Observer>
          </Grid>
          <Grid item xs={12} sm={6}></Grid>

          <Grid item xs={12}>
            <Typography variant="h6">GST Registration Confirmation:</Typography>
          </Grid>
          <Grid item xs={12}>
            <div
              style={{
                backgroundColor: "#ffecb3",
                padding: "20px",
                borderRadius: "5px",
                display: "flex",
                gap: "10px",
                textAlign: "justify",
              }}
            >
              <WarningIcon />
              <Typography>
                Please check the box to confirm that you are GST registered. To
                understand your tax obligations we recommend you seek advice
                from a professional advisor.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <FormGroup>
              <Observer>
                {() => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={legalDetails.riderRegisteredForGst}
                        onChange={handleRiderRegisteredForGst}
                      />
                    }
                    label="I am registered for GST in Australia"
                  />
                )}
              </Observer>
            </FormGroup>
          </Grid>
          <Grid item xs={12}>
            <Typography sx={{ marginBottom: 2 }}>I consent that:</Typography>
            <FormGroup>
              <Observer>
                {() => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={legalDetails.firstChecked}
                        onChange={handleFirstChange}
                      />
                    }
                    label="The provided “Legal Name” matches my full name as it appears on the photo ID(s) that will be used for verifying my Identity in the next steps."
                  />
                )}
              </Observer>
              <Observer>
                {() => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={legalDetails.secondChecked}
                        onChange={handleSecondChange}
                      />
                    }
                    label="The ABN Number belongs to a Registered and Active “Sole Trader” business structure (Partnership or Company is not accepted)."
                  />
                )}
              </Observer>

              <Observer>
                {() => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={legalDetails.thirdChecked}
                        onChange={handleThirdChange}
                      />
                    }
                    label="The provided contact details (Address and Phone Number) are my Legal contact details that could be used for any further communications by “Bearer” or “SeyCo Pty Ltd”."
                  />
                )}
              </Observer>
            </FormGroup>
            <Typography sx={{ marginTop: 3 }}>
              For obtaining an ABN or checking your ABN details, you can use
              <Link href={soleTraderUrl} target="_blank" rel="noreferrer">
                {" "}
                this link
              </Link>
              .
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" textAlign="justify">
              Public Liability:
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormGroup className="public-liability-insurance-detail">
              <Observer>
                {() => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={
                          legalDetails.publicLiabilityInsuranceNotProvided
                        }
                        onChange={handlePublicLiabilityInsuranceNotProvided}
                      />
                    }
                    label=""
                  />
                )}
              </Observer>
              <span>
                I choose not to submit my Public Liability Insurance details at
                this time. I acknowledge and understand the terms related to
                public liability as stipulated in the{" "}
                <a
                  href={delivery_person_agreement}
                  className="public-liability-insurance-detail-anker"
                  target="_blank"
                >
                  Bearer Contractor Agreement
                </a>
                , specifically in clauses 24.b, 26.a, and 27.a.iv.
              </span>
            </FormGroup>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Observer>
              {() => (
                <BearerTextField
                  label="Insurer Company"
                  value={legalDetails.publicLiabilityInsuranceCompany}
                  onChange={(e: any) =>
                    legalDetails.setPublicLiabilityInsurerCompany(
                      e.target.value
                    )
                  }
                  onValidate={(v: any) =>
                    legalDetails.setPublicLiabilityInsurerCompanyValid(v)
                  }
                  validator={validateGeneralText}
                  disabled={legalDetails.publicLiabilityInsuranceNotProvided}
                />
              )}
            </Observer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Observer>
              {() => (
                <BearerTextField
                  label="Insurance Number"
                  value={legalDetails.publicLiabilityInsuranceNumber}
                  onChange={(e: any) =>
                    legalDetails.setPublicLiabilityInsuranceNumber(
                      e.target.value
                    )
                  }
                  onValidate={(v: any) =>
                    legalDetails.setPublicLiabilityInsuranceNumberValid(v)
                  }
                  validator={validateGeneralText}
                  disabled={legalDetails.publicLiabilityInsuranceNotProvided}
                />
              )}
            </Observer>
          </Grid>
          <Grid item xs={12} sm={6} className="observer-datepicker">
            <Observer>
              {() => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    minDate={new Date(yyyy, mm + 2, dd + 1)}
                    disabled={legalDetails.publicLiabilityInsuranceNotProvided}
                    openTo="year"
                    views={["year", "month", "day"]}
                    value={legalDetails.publicLiabilityValidityDate}
                    onChange={(newValue: any) => {
                      if (newValue) {
                        try {
                          let myDate = new Date(newValue);
                          let sortedDate = myDate.toString().split(/\+|-/)[0];
                          let finalDate = new Date(sortedDate)
                            .toISOString()
                            .split("T")[0];
                          legalDetails.setPublicLiabilityValidityDate(
                            finalDate
                          );
                        } catch (_) { }
                      }
                    }}
                    inputFormat="yyyy/MM/dd"
                    mask="____/__/__"
                    label="Expiry Date"
                    renderInput={(params: any) => (
                      <BearerTextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          readOnly: true,
                        }}
                      />
                    )}
                  />
                  <Tooltip
                    title="Your cover must have at least 2 months validity"
                    placement="bottom-end"
                    className="tooltip"
                  >
                    <InfoIcon />
                  </Tooltip>
                </LocalizationProvider>
              )}
            </Observer>
          </Grid>
          <Grid
            item
            xs={12}
            direction="row"
            sx={{ display: "flex", alignItems: "center", gap: 1 }}
          >
            {!legalDetails.publicLiabilityInsuranceNotProvided && (
              <>
                <Typography>Attach Certificate of Currency:</Typography>
                <Observer>{() => <PublicLiabilityInsuranceFile />}</Observer>
              </>
            )}
          </Grid>

          <Grid item xs={12} sm={6} textAlign="center" sx={{ marginTop: 3 }}>
            <Button variant="contained" onClick={backHandler}>
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={6} textAlign="center" sx={{ marginTop: 3 }}>
            <Observer>
              {() => {
                const isDateTimeValid =
                  legalDetails.publicLiabilityValidityDate?.length === 10
                    ? true
                    : false;
                const isFileSelected =
                  legalDetails.publicLiabilityInsuranceFile === ""
                    ? false
                    : true;

                var valid = false;
                if (legalDetails.publicLiabilityInsuranceNotProvided) {
                  valid =
                    legalDetails.legalNameValid &&
                    legalDetails.abnNumberValid &&
                    legalDetails.phoneValid &&
                    legalDetails.streetNameValid &&
                    legalDetails.StreetNumberValid &&
                    legalDetails.cityValid &&
                    legalDetails.postCodeValid &&
                    legalDetails.firstChecked &&
                    legalDetails.secondChecked &&
                    legalDetails.thirdChecked;

                  legalDetails.setPublicLiabilityValidityDate("");
                  legalDetails.setPublicLiabilityInsuranceNumber("");
                  legalDetails.setPublicLiabilityInsurerCompany("");
                } else {
                  valid =
                    legalDetails.legalNameValid &&
                    legalDetails.abnNumberValid &&
                    legalDetails.phoneValid &&
                    legalDetails.streetNameValid &&
                    legalDetails.StreetNumberValid &&
                    legalDetails.cityValid &&
                    legalDetails.postCodeValid &&
                    legalDetails.firstChecked &&
                    legalDetails.secondChecked &&
                    legalDetails.thirdChecked &&
                    isDateTimeValid &&
                    legalDetails.publicLiabilityInsuranceNumberValid &&
                    legalDetails.publicLiabilityInsuranceComponyValid &&
                    isFileSelected;
                }
                return (
                  <>
                    <Button variant="contained" type="submit" disabled={!valid}>
                      Next
                    </Button>
                    {loading && <DisabledClickLoading />}
                  </>
                );
              }}
            </Observer>
          </Grid>
        </Grid>
      </form>
    </>
  );
});
export default LegalDetails;
