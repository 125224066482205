import React, { useEffect } from "react";
import { Button, Grid } from "@mui/material";
import { observer, Observer } from "mobx-react-lite";
import DisabledClickLoading from "../../../components/DisabledClickLoading";
import BearerTextField from "../../../components/fields/BearerTextField";
import { validateEmail } from "../../../components/validation/FieldsValidation";
import { useAppState } from "../../../states/app-state";
import { log } from "../../../components/error/error";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
const FirstStep: React.FC = observer(() => {
  const appState = useAppState();
  const { signUp } = appState.registration;
  const [loading, setLoading] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleSendEmail = async () => {
    try {
      setLoading(true);
      await signUp.sendSignInLinkToEmail();
      logout();
      setDialogOpen(true);
    } catch (err: any) {
      log(err);
      setLoading(false);
    }
  };

  const logout = () => {
    try {
      appState.login.signout();
    } catch (err: any) {}
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    appState.registration.setNoStep();
  };

  return (
    <Grid container spacing={1} sx={{ margin: "8px 0" }}>
      <Grid item xs={12}>
        <Observer>
          {() => (
            <BearerTextField
              sx={{ paddingBottom: "10px" }}
              label="Email"
              type="email"
              value={signUp.registrationEmail}
              onChange={(e: any) => signUp.setRegistrationEmail(e.target.value)}
              onValidate={(v: any) => signUp.setRegistrationEmailValid(v)}
              validator={validateEmail}
            />
          )}
        </Observer>
      </Grid>

      <Observer>
        {() => {
          const valid = signUp.registrationEmailValid;
          return (
            <>
              <Button
                variant="contained"
                sx={{ display: "block", margin: "20px auto 0 auto" }}
                onClick={handleSendEmail}
                disabled={!valid}
              >
                Send Email
              </Button>
              {loading && <DisabledClickLoading />}
            </>
          );
        }}
      </Observer>
      <Dialog open={dialogOpen} className="sucess-dialog-dilog">
        <DialogContent>
          <DialogContentText>
            An Email has been sent to the provided address. Please check your
            email and follow the provided instructions to continue the
            registration.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClick}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
});
export default FirstStep;
