import VerticalLinearStepper from "./stepper/VerticalLinearStepper";
import BearerLogo from "../assets/BearerLogo.png";
import { makeStyles } from '@mui/styles';
import backGround from "../assets/backGround.png";

const useStyles = makeStyles({
    container: {
        width: 290,
        padding: 20,
        backgroundImage: `url(${backGround})`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        minHeight: '100%',
    },
});

const LeftSide: React.FC = () => {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <img src={BearerLogo} width="150px" alt="logo" />
            <VerticalLinearStepper />
        </div >
    )
}
export default LeftSide;