import React from "react";
import { Typography } from "@mui/material";
import { StepsEnumValues, useAppState } from "../../states/app-state";


const MobileLinearStepper: React.FC = () => {
    const appState = useAppState();
    const activeStep = StepsEnumValues.findIndex(s => s === appState.registration.step);

    return (
        <Typography textAlign="center" variant="h5" sx={{ paddingY: '20px' }}>{StepsEnumValues[activeStep]}</Typography>
    )
}
export default MobileLinearStepper;