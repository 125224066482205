import { FormControlLabel, Radio, RadioProps } from "@mui/material";


type IRightRadioButtonProps = RadioProps & {
    label: string;
}
const RadioButton: React.FC<IRightRadioButtonProps> = ({ label, value, ...props }) => {
    return (
        <FormControlLabel
            value={value}
            control={<Radio color="primary" {...props} />}
            label={label}
        />
    )
}
export default RadioButton;