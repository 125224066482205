import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  Tooltip,
} from "@mui/material";

import AvatarEditor from "./AvatarEditor";
import BearerTextField from "../../../components/fields/BearerTextField";
import GenderSelectField from "../../../components/fields/GenderSelectField";
import DisabledClickLoading from "../../../components/DisabledClickLoading";
import { observer, Observer } from "mobx-react-lite";
import {
  validateName,
  validateOnlyMoreThanTwoLetter,
} from "../../../components/validation/FieldsValidation";
import { AddressType, useAppState } from "../../../states/app-state";
import ManualPersonalAddress from "../addressFields/ManualPersonalAddress";
import AutocompleteAddress from "../addressFields/AutocompleteAddress";
import InfoIcon from "@mui/icons-material/Info";
import {
  extractPostCode,
  extractStreetName,
  extractStreetNumber,
  extractCity,
  extractState,
  extractUnitNumber,
} from "../addressFields/ExtractAddress";
import LogoutButton from "../../login/LogoutButton";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DriversLicenseFile from "./Driver'sLicenceFile";
import "../../../styles.css";
import { log } from "../../../components/error/error";
import WhatsAppFeedBack from "../../../components/common/WhatsAppFeedBack";

const PersonalDetails: React.FC = observer(() => {
  const appState = useAppState();
  const { personalDetails } = appState.registration;
  const [loading, setLoading] = React.useState(false);
  const [IDErrorClass, setIDErrorClass] = React.useState(
    "personal-detail-file-upload"
  );

  React.useEffect(() => {
    appState.getOnboardingRemoteConfigParameters();
  }, [appState]);



  const nextHandler = async (e: any) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    try {
      setLoading(true);
      await personalDetails.setPersonalDetails();
      if (appState.errorMessage?.message.includes("image")) {
        personalDetails.setDriversLicenseFile("");
        setIDErrorClass("personal-detail-file-upload error-file");
      } else {
        setIDErrorClass("personal-detail-file-upload");
      }
      setLoading(false);
    } catch (err) {
      log(err);
      setLoading(false);
    }
  };

  const handleChange = () => {
    personalDetails.setChecked(!personalDetails.checked);
  };
  const today = new Date();
  const dd = today.getDate();
  const mm = today.getMonth();
  const yyyy = today.getFullYear();

  return (
    <>
      {appState.onboardingRemoteConfigParameters && <WhatsAppFeedBack />}
      <div style={{ marginBottom: "10px" }}>
        <LogoutButton />
      </div>
      <form onSubmit={nextHandler}>
        <Typography className="Thisisp">
          Please provide your “Personal Details” and upload your “Profile Photo”
          which will be shown on customers’ mobile applications (for identifying
          the right delivery person) while an order has been accepted by you on
          Bearer platform.
          <br />
          Bellow information would also be used for ID Verification and other
          necessary legal check purposes and might be shared with our
          third-party partners during the registration. Please ensure you
          carefully enter all the asked information. Providing correct and
          accurate details will help us to process your application faster.
          <br />
        </Typography>
        <Grid container spacing={2} sx={{ marginTop: 2 }}>
          <Grid item xs={12} sm={3} sx={{ textAlign: "center" }}>
            <AvatarEditor />
            <Typography
              sx={{ color: `${personalDetails.avatar ? "green" : "red"}` }}
            >
              Profile Photo{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Observer>
              {() => (
                <BearerTextField
                  type="text"
                  label="First Name"
                  value={personalDetails.firstName}
                  onChange={(e: any) =>
                    personalDetails.setFirstName(e.target.value)
                  }
                  onValidate={(v: any) => personalDetails.setFirstNameValid(v)}
                  validator={validateOnlyMoreThanTwoLetter}
                />
              )}
            </Observer>
            <br />
            <br />
            <Observer>
              {() => (
                <BearerTextField
                  type="text"
                  label="Middle Name"
                  value={personalDetails.middleName}
                  onChange={(e: any) =>
                    personalDetails.setMiddleName(e.target.value)
                  }
                />
              )}
            </Observer>
            <br />
            <br />
            <Observer>
              {() => (
                <BearerTextField
                  type="text"
                  label="Last Name"
                  value={personalDetails.lastName}
                  onChange={(e: any) =>
                    personalDetails.setLastName(e.target.value)
                  }
                  onValidate={(v: any) => personalDetails.setLastNameValid(v)}
                  validator={validateOnlyMoreThanTwoLetter}
                />
              )}
            </Observer>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div
              className="personal-detail-dob"
              style={{ position: "relative" }}
            >
              <Observer>
                {() => (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      label="DOB"
                      openTo="year"
                      views={["year", "month", "day"]}
                      value={personalDetails.birthday}
                      onChange={(newValue: any) => {
                        if (newValue) {
                          try {
                            let myDate = new Date(newValue);
                            let sortedDate = myDate.toString().split(/\+|-/)[0];
                            let finalDate = new Date(sortedDate)
                              .toISOString()
                              .split("T")[0];
                            personalDetails.setBirthday(finalDate);
                          } catch (_) { }
                        }
                      }}
                      maxDate={new Date(yyyy - 18, mm, dd - 1)}
                      minDate={new Date(yyyy - 100, mm, dd - 1)}
                      inputFormat="yyyy/MM/dd"
                      mask="____/__/__"
                      renderInput={(params: any) => (
                        <BearerTextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            readOnly: true,
                          }}
                        />
                      )}
                    />
                    <Tooltip
                      title="You must be at least 18 years of age"
                      placement="bottom-end"
                      className="tooltip"
                    >
                      <InfoIcon />
                    </Tooltip>
                  </LocalizationProvider>
                )}
              </Observer>
            </div>
            <br />
            <GenderSelectField />
          </Grid>
          <Grid item xs={12}>
            <div style={{ position: "relative" }}>
              <Observer>
                {() => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={personalDetails.checked}
                        onChange={handleChange}
                      />
                    }
                    label="No match?"
                  />
                )}
              </Observer>
              <Tooltip
                style={{ position: "absolute", top: "8px" }}
                title="Start typing the address and then select it from the list e.g. “447 Collins” to return “447 Collins St, MELBOURNE VIC 3000”. If not listed, select 'No match?' and enter the address manually"
                placement="bottom"
                className="tooltip"
              >
                <InfoIcon />
              </Tooltip>
            </div>

            <Grid item xs={12}>
              <Observer>
                {() => (
                  <>
                    {!personalDetails.checked && (
                      <AutocompleteAddress
                        onChange={(place: AddressType | null) => {
                          personalDetails.setPosteCode(extractPostCode(place));
                          personalDetails.setStreetName(
                            extractStreetName(place)
                          );
                          personalDetails.setStreetNumber(
                            extractStreetNumber(place)
                          );
                          personalDetails.setCity(extractCity(place));
                          personalDetails.setState(extractState(place));
                          personalDetails.setUnitNumber(
                            extractUnitNumber(place)
                          );
                        }}
                      />
                    )}
                    <ManualPersonalAddress />
                  </>
                )}
              </Observer>
            </Grid>
          </Grid>
          <h4 className="personal-detail">
            In order to verify your profile photo,we need to have a scanned copy
            of your Photo ID.
          </h4>
          <Grid item xs={12} sm={6}>
            <div className="personal-detail-list">
              <h4>Suggested types of Photo ID:</h4>
              <ul>
                <li>"Australian Driver’s License"</li>
                <li>"Australian Proof of Age Card"</li>
                <li>"Government issued Photo Cards"</li>
              </ul>
            </div>
          </Grid>
          <Grid item xs={12} sm={6}>
            <div
              className={
                personalDetails.driversLicenseFile !== ""
                  ? "personal-detail-file-upload uploaded"
                  : IDErrorClass
              }
            >
              <DriversLicenseFile />
              <h4>File uploaded</h4>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} textAlign="center" sx={{ marginTop: 3 }}>
            <Observer>
              {() => {
                const isBirthday =
                  personalDetails.birthday?.length === 10 ? true : false;
                const isLicenseFile =
                  personalDetails.driversLicenseFile === "" ? false : true;

                const valid =
                  personalDetails.firstNameValid &&
                  personalDetails.lastNameValid &&
                  personalDetails.streetNameValid &&
                  personalDetails.streetNumberValid &&
                  personalDetails.posteCodeValid &&
                  personalDetails.cityValid &&
                  (personalDetails.gender !== "" ? true : false) &&
                  (personalDetails.avatar !== "" ? true : false) &&
                  isLicenseFile &&
                  isBirthday;

                return (
                  <>
                    <Button variant="contained" type="submit" disabled={!valid}>
                      Next
                    </Button>
                    {loading && <DisabledClickLoading />}
                  </>
                );
              }}
            </Observer>
          </Grid>
        </Grid>
      </form>
    </>
  );
});
export default PersonalDetails;
