import React from "react";
import { Typography } from "@mui/material";
import HorizontalLinearStepper from "../../stepper/HorizontalLinearStepper";
import ThirdStep from "./ThirdStep";
import FirstStep from "./FirstStep";
import SecondStep from "./SecondStep";
import { AuthenticationStepsEnum, useAppState } from "../../../states/app-state";
import { observer } from "mobx-react-lite";


const ContactDetails: React.FC = observer(() => {
    const appState = useAppState();
    const { authentication } = appState.registration.signUp;
    // const authentication = AuthenticationStepsEnum.MobileNumber as AuthenticationStepsEnum;


    return (
        <>
            <Typography>
                To register as a new user, we need to have:
                <br /><br />
                1. Your Email Address (Active & Accessible only by you)
                <br />
                2. A valid Australian Mobile Phone Number which is in your possession
                <br /><br />
                to set your user credentials for future user authentications while completing your registration or using “Bearers” Mobile application
            </Typography>
            <HorizontalLinearStepper />
            {authentication === AuthenticationStepsEnum.Email && <FirstStep />}
            {authentication === AuthenticationStepsEnum.Password && <SecondStep />}
            {authentication === AuthenticationStepsEnum.MobileNumber && <ThirdStep />}
        </>
    )
})
export default ContactDetails;

