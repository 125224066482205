import React from "react";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  Typography,
} from "@mui/material";
import StripeLogo from "../../assets/StripeLogo.png";
import { StripeResponseEnum, useAppState } from "../../states/app-state";
import { observer, Observer } from "mobx-react-lite";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import LogoutButton from "../login/LogoutButton";
import { log } from "../../components/error/error";
import WhatsAppFeedBack from "../../components/common/WhatsAppFeedBack";

const StripeOnboarding: React.FC = observer(() => {
  const appState = useAppState();
  const { stripe } = appState.registration;
  React.useEffect(() => {
    appState.getOnboardingRemoteConfigParameters();
  }, [appState]);

  const [loading, setLoading] = React.useState(false);
  const nextHandler = async () => {
    window.scrollTo(0, 0);
    try {
      setLoading(true);
      await stripe.onboardingStripe();
      setLoading(false);
    } catch (err: any) {
      log(err);
      setLoading(false);
    }
  };

  const [stripeLoading, setStripeLoading] = React.useState(false);
  const stripeHandler = async () => {
    try {
      setStripeLoading(true);
      await stripe.onboardingGetStripeExpressLink();
      setStripeLoading(false);
    } catch (err: any) {
      setStripeLoading(false);
    }
  };

  const backHandler = () => {
    appState.registration.setModesOfTransportStep();
  };

  const changeHandler = (e: any) => {
    stripe.setChecked(!stripe.checked);
  };
  React.useEffect(() => {
    window.open(stripe.stripeUrl, "_parent");
  });

  React.useEffect(() => {
    stripe.showStripeResponse();
  }, [appState, stripe]);
  return (
    <>
      {appState.onboardingRemoteConfigParameters && <WhatsAppFeedBack />}
      <div style={{ marginBottom: "10px" }}>
        <LogoutButton />
      </div>
      <Typography>
        “Bearer” partners with “Stripe©” for fast and secure payments. This
        means that “Bearer” doesn’t process any payments and/or bank
        transactions by itself and all will be handled by our partner,
        “Stripe©”.
      </Typography>
      <Typography>
        As we do not keep any data like your bank details on our servers -for
        keeping all information safe, you need to provide “Stripe©” with some
        details by clicking bellow button.
      </Typography>
      <FormGroup sx={{ marginTop: "20px" }}>
        <Observer>
          {() => (
            <FormControlLabel
              control={
                <Checkbox
                  checked={stripe.checked}
                  onChange={changeHandler}
                  disabled={stripe.checked ? true : false}
                />
              }
              label="I hereby give the permission to Bearer to send my basic personal information (Name, Phone Number, Address, etc.) to “Stripe©” for their onboarding."
            />
          )}
        </Observer>
      </FormGroup>
      <Grid container spacing={2} sx={{ marginTop: "10px" }}>
        <Grid item xs={12} textAlign="center">
          {stripe.stripeResponse === StripeResponseEnum.MainStripeButton && (
            <Button disabled={!stripe.checked}>
              <img src={StripeLogo} alt="" onClick={stripeHandler} />
            </Button>
          )}

          {stripe.stripeResponse === StripeResponseEnum.StripeButton && (
            <>
              <Typography color="#ff9800" sx={{ textAlign: "justify" }}>
                Stripe account registration was not completed successfully.
                Please click on bellow link to continue the process.
              </Typography>
              <img src={StripeLogo} alt="" onClick={stripeHandler} />
            </>
          )}
          {stripeLoading && <DisabledClickLoading />}
          {stripe.stripeResponse === StripeResponseEnum.Successful && (
            <Typography sx={{ color: "#17d683", textAlign: "justify" }}>
              Your account with Stripe was successfully registered.
            </Typography>
          )}
          {stripe.stripeResponse === StripeResponseEnum.UnSuccessful && (
            <Typography sx={{ color: "#c00000", textAlign: "justify" }}>
              Stripe account registration was completed successfully. However,
              some provided details need to be updated before receiving account
              payments. Bearer will remind you later to do so.
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} textAlign="center" sx={{ marginTop: 3 }}>
          <Observer>
            {() => {
              const disabled = stripe.backBtn;
              return (
                <Button
                  variant="contained"
                  disabled={disabled}
                  onClick={backHandler}
                >
                  Back
                </Button>
              );
            }}
          </Observer>
        </Grid>
        <Grid item xs={12} sm={6} textAlign="center" sx={{ marginTop: 3 }}>
          <Observer>
            {() => {
              const valid = stripe.nextBtn;
              return (
                <>
                  <Button
                    variant="contained"
                    onClick={nextHandler}
                    disabled={!valid}
                  >
                    Next
                  </Button>
                  {loading && <DisabledClickLoading />}
                </>
              );
            }}
          </Observer>
        </Grid>
      </Grid>
    </>
  );
});
export default StripeOnboarding;
