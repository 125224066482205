import React from "react";
import { GenderEnumValues, useAppState } from "../../states/app-state";
import { observer } from "mobx-react-lite";
import { TextField, MenuItem } from "@mui/material";

const GenderSelectField: React.FC = observer(() => {
    const appState = useAppState();
    const { personalDetails } = appState.registration;
    return (
        <TextField
            select
            label="Gender"
            value={personalDetails.gender}
            onChange={(e) => personalDetails.setGender(e.target.value)}
            variant="filled"
            fullWidth
            size="small"
        >
            {GenderEnumValues.map((gender, index) => (
                <MenuItem key={index} value={gender}>
                    {gender}
                </MenuItem>
            ))}
        </TextField>
    )
})
export default GenderSelectField;