import React, { useState } from "react";
import { Grid } from "@mui/material";
import logo from "../../assets/bearer-logo-footer.svg";
import emailLogo from "../../assets/email-newletter.svg";
import aus from "../../assets/bearer-logo-aust.svg";
import instagram from "../../assets/instagram-footer.png";
import facebook from "../../assets/facebook-footer.png";
import twitter from "../../assets/twitter-footer.png";
import linkedin from "../../assets/linkedin-footer.png";
import "./footer.scss";
import Input from "../input/Input";
import * as footerServicve from "./footerServices";
import Spinner from "../spinner/Spinner";
import { ToastContainer, toast } from "react-toastify";

function Footer() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [isLoading, setLoading] = useState(false);

  const baseUrl = window.localStorage.getItem("bearerWebsiteBaseUrl");
  const handleNewsFeed = async () => {
    if (email.length < 5) {
      return;
    }
    setLoading(true);
    try {
      const result = await footerServicve.websiteNewsletter(email);
      setLoading(false);
      if (result) {
        switch (result.data.code) {
          case 200:
            toast.success("Successfully subscribed!");
            setEmail("");
            return;
          default:
            return toast.error(result.data.message);
        }
      }
    } catch (error) {
      toast.error(error.message);
      setLoading(false);
    }
  };

  const renderSpinner = () => {
    if (isLoading) {
      return <Spinner />;
    }
  };
  const handleEmail = (value) => {
    setEmail(value);
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    if (!regex.test(value)) {
      setEmailError("Email address is not valid!");
    } else {
      setEmailError("");
    }
  };
  return (
    <div className="footer">
      <ToastContainer />
      {renderSpinner()}
      <div className="logo-wrapper">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3} md={7} lg={7}>
            <a href={baseUrl} target="_blank" rel="noreferrer">
              <img src={logo} className="logo" alt="logo" />
            </a>
          </Grid>
          <Grid item xs={12} sm={9} md={5} lg={5}>
            <p>Parcels are closer to their destinations than ever!</p>
          </Grid>
        </Grid>
      </div>
      <div className="middle-footer-info">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={7}>
            <Grid container spacing={2}>
              <Grid item xs={6} sm={6} md={6}>
                <ul>
                  <li>
                    <a href={baseUrl} target="_blank" rel="noreferrer">
                      Bearer for Everyone
                    </a>
                  </li>
                  <li>
                    <a
                      href={baseUrl + "/Business"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Bearer for Business
                    </a>
                  </li>
                  <li>
                    <a
                      href={baseUrl + "/Bearers"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Become a Bearer
                    </a>
                  </li>
                  <li>
                    <a
                      href={baseUrl + "/About"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      About Bearer
                    </a>
                  </li>
                  <li>
                    <a
                      href={baseUrl + "/Blog"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Bearer Blog
                    </a>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <ul>
                  <li>
                    <a
                      href={baseUrl + "/FAQs"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      FAQs
                    </a>
                  </li>
                  <li>
                    <a
                      href={baseUrl + "/Support"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Support
                    </a>
                  </li>
                  <li>
                    <a
                      href={baseUrl + "/Packaging"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Packaging
                    </a>
                  </li>
                  <li>
                    <a
                      href={baseUrl + "/Contact"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Contact Us
                    </a>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <div className="newsletter">
              <p>Subscribe to our Newsletter to get the latest updates</p>
              <div className="newslette-input-wrapper">
                <img src={emailLogo} alt="email-logo" />
                <Input
                  type="String"
                  name="profileFullName"
                  value={email}
                  error={emailError}
                  onChange={(e) => handleEmail(e.target.value)}
                  placeHolder="Enter your email"
                />
                <button className="subscribe" onClick={handleNewsFeed}>
                  Subscribe
                </button>
              </div>
              <div className="social-wrapper">
                <div className="social-link">
                  <ul className="social">
                    <li>
                      <a
                        href="https://www.instagram.com/Bearer.AU/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={instagram} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.facebook.com/Bearer.AU"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={facebook} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://twitter.com/Bearer_AU"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={twitter} alt="" />
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.linkedin.com/company/bearer-au/"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={linkedin} alt="" />
                      </a>
                    </li>
                  </ul>
                  <div className="privacy">
                    <ul>
                      <li>
                        <a
                          href={baseUrl + "/Legal"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Legal
                        </a>
                      </li>
                      <li>
                        <a
                          href={baseUrl + "/Legal/PrivacyPolicy"}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="aus-logo">
                  <img src={aus} className="aus" alt="logo" />
                </div>
              </div>
            </div>
          </Grid>
        </Grid>
      </div>
      <div className="cite">
        <p>
          © 2024 Bearer | SeyCo Pty Ltd. <span>ABN: 64 626 871 225</span>
          <span>All rights reserved</span>
        </p>
      </div>
    </div>
  );
}

export default Footer;
