import { Button } from "@mui/material";
import { Observer } from "mobx-react-lite";
import { useAppState } from "../../states/app-state";
import Login from "../../assets/login-register.png";

const SignUpPage: React.FC = () => {
  const appState = useAppState();
  const signUpHandler = () => {
    appState.registration.setTermsAndConditionStep();
  };

  return (
    <>
      <div className="sign-up-register-image">
        <img src={Login} alt="" />
      </div>
      <Observer>
        {() => (
          <Button
            onClick={signUpHandler}
            className="login-register-btn"
            variant="contained"
          >
            Register Now
          </Button>
        )}
      </Observer>
    </>
  );
};
export default SignUpPage;
