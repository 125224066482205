import React from "react";
import { observer, Observer } from "mobx-react-lite";
import BicycleIcon from "../../components/icons/BicycleIcon";
import MotorBikeIcon from "../../components/icons/MotorBikeIcon";
import WalkIcon from "../../components/icons/WalkIcon";
import RadioButton from "../../components/radioButton/RadioButton";
import { CycleEnum, MotorbikeEnum, useAppState } from "../../states/app-state";
import CloseIcon from "@mui/icons-material/Close";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import {
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import MotorbikeDetailsForm from "../motorbikeDetailsForm/MotorbikeDetailsForm";
import LogoutButton from "../login/LogoutButton";
import { log } from "../../components/error/error";
import WhatsAppFeedBack from "../../components/common/WhatsAppFeedBack";

const ModesOfTransport: React.FC = observer(() => {

  const appState = useAppState();
  const { modesOfTransport } = appState.registration;

  React.useEffect(() => {
    window.history.replaceState({}, "", "/");
    appState.getOnboardingRemoteConfigParameters();
  }, [appState]);


  const handleHasMotorbike = () => {
    modesOfTransport.setOpen(true);
  };

  const [showPopup, setShowPopup] = React.useState(false);
  const handleShowPopup = () => {
    setShowPopup(true);
  };
  const handleClosePopup = () => {
    if (modesOfTransport.motorbikeId) {
      modesOfTransport.setMotorbike(MotorbikeEnum.HasMotorbike);
      setShowPopup(false);
    } else {
      setShowPopup(false);
    }
  };

  const [loading, setLoading] = React.useState(false);
  const nextHandler = async () => {
    window.scrollTo(0, 0);
    try {
      setLoading(true);
      await modesOfTransport.setModesOfTransport();
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
      log(err);
    }
  };

  const backHandler = () => {
    appState.registration.setLegalDetailsStep();
  };

  const clickHandler = () => {
    modesOfTransport.setMotorbike(MotorbikeEnum.HasNotMotorbike);
    if (modesOfTransport.motorbikeId) {
      handleShowPopup();
    }
  };

  return (
    <>
      {appState.onboardingRemoteConfigParameters && <WhatsAppFeedBack />}
      {modesOfTransport.open && <MotorbikeDetailsForm />}
      <div style={{ marginBottom: "10px" }}>
        <LogoutButton />
      </div>
      <DeleteMotorbike open={showPopup} handleClose={handleClosePopup} />
      <Typography>
        Being a Bearer is so exciting. You have time-flexibility and complete
        freedom while choosing the way you want to deliver the parcels. All the
        Bearers can provide the service as “Walking Bearers” for short distance
        delivery requests. However, you might want to expand your experience and
        earn even more by using a Bicycle or a Motorbike to receive more
        frequent order requests.
        <br />
        <br />
        Being able to work as a “Walking Bearer” is already selected by default
        for all applicants. If you are also keen to join the fleet as a
        “Cyclist” and/or a “Motorbike Rider”, choose “Yes” for each.
        <br />
        <br />
        *Please provide details of the motorbike you are going to use only if
        you own one. Hired motorbikes are not accepted for providing the
        service.
      </Typography>
      <div style={{ display: "flex", margin: "20px auto", width: "80%" }}>
        <div style={{ flex: "1" }}>
          <WalkIcon />
        </div>
        <div style={{ flex: "1" }}>
          <MotorBikeIcon />
        </div>
        <div style={{ flex: "1" }}>
          <BicycleIcon />
        </div>
      </div>

      <Typography>Walking Bearer:</Typography>
      <RadioButton label="Yes" checked disabled />
      <RadioButton label="No" disabled />
      <Typography>Cycling Bearer:</Typography>
      <Observer>
        {() => (
          <RadioButton
            label="Yes"
            value={CycleEnum.HasCycle}
            checked={modesOfTransport.cycle === CycleEnum.HasCycle}
            onClick={() => modesOfTransport.setCycle(CycleEnum.HasCycle)}
          />
        )}
      </Observer>
      <Observer>
        {() => (
          <RadioButton
            label="No"
            value={CycleEnum.HasNotCycle}
            checked={modesOfTransport.cycle === CycleEnum.HasNotCycle}
            onClick={() => modesOfTransport.setCycle(CycleEnum.HasNotCycle)}
          />
        )}
      </Observer>
      <Typography>Riding Bearer:</Typography>
      <Observer>
        {() => (
          <>
            <RadioButton
              label="Yes"
              value={MotorbikeEnum.HasMotorbike}
              checked={
                modesOfTransport.motorbike === MotorbikeEnum.HasMotorbike
              }
              onClick={handleHasMotorbike}
              name="yes"
            />
          </>
        )}
      </Observer>
      <Observer>
        {() => (
          <>
            <RadioButton
              label="No"
              value={MotorbikeEnum.HasNotMotorbike}
              checked={
                modesOfTransport.motorbike === MotorbikeEnum.HasNotMotorbike
              }
              onClick={clickHandler}
            />
          </>
        )}
      </Observer>
      <Grid container spacing={2}>
        <Grid item sm={12} sx={{ margin: "3px 0" }}>
          <Observer>
            {() => (
              <>
                {modesOfTransport.motorbikeId && (
                  <>
                    <Button
                      variant="outlined"
                      sx={{ marginRight: 2 }}
                      onClick={handleHasMotorbike}
                    >
                      Edit
                    </Button>
                    <Button variant="outlined" onClick={handleShowPopup}>
                      Delete
                    </Button>
                  </>
                )}
              </>
            )}
          </Observer>
        </Grid>
        <Grid item xs={12} sm={6} textAlign="center" sx={{ marginTop: 3 }}>
          <Button variant="contained" onClick={backHandler}>
            Back
          </Button>
        </Grid>
        <Grid item xs={12} sm={6} textAlign="center" sx={{ marginTop: 3 }}>
          <Observer>
            {() => (
              <>
                <Button
                  variant="contained"
                  onClick={nextHandler}
                  disabled={loading}
                >
                  Next
                </Button>
                {loading && <DisabledClickLoading />}
              </>
            )}
          </Observer>
        </Grid>
      </Grid>
    </>
  );
});
export default ModesOfTransport;

interface IDeleteMotorbikeProps {
  open: boolean;
  handleClose: () => void;
}
const DeleteMotorbike: React.FC<IDeleteMotorbikeProps> = observer(
  ({ open, handleClose }) => {
    const appState = useAppState();
    const { modesOfTransport } = appState.registration;

    const [loading, setLoading] = React.useState(false);
    const handleRemoveMotorBike = async () => {
      try {
        setLoading(true);
        await modesOfTransport.removeMotorbike(modesOfTransport.motorbikeId!);
        handleClose();
        setLoading(false);
      } catch (err: any) {
        setLoading(false);
      }
    };
    const closeHandler = () => {
      if (modesOfTransport.motorbikeId) {
        modesOfTransport.setMotorbike(MotorbikeEnum.HasMotorbike);
        handleClose();
      } else {
        handleClose();
      }
    };
    return (
      <Dialog open={open}>
        <DialogTitle>
          <IconButton
            onClick={closeHandler}
            sx={{
              position: "absolute",
              right: 10,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ marginTop: 2 }}>
              Are you sure you want to delete your motorbike?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: "center" }}>
          <Observer>
            {() => (
              <>
                <Button variant="contained" onClick={closeHandler}>
                  No
                </Button>
                <Button
                  variant="contained"
                  onClick={handleRemoveMotorBike}
                  disabled={loading}
                >
                  Yes
                </Button>
                {loading && <DisabledClickLoading />}
              </>
            )}
          </Observer>
        </DialogActions>
      </Dialog>
    );
  }
);
