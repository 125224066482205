import { Dialog, DialogTitle, Typography, IconButton, DialogContent, DialogContentText } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useAppState } from "../../states/app-state";
import CloseIcon from '@mui/icons-material/Close';
import MotorbikeDetails from "./MotorbikeDetails";
import InsuranceDetails from "./InsuranceDetails";



const MotorbikeDetailsForm: React.FC = observer(() => {

    const appState = useAppState();
    const { modesOfTransport } = appState.registration;
    const activated = modesOfTransport.activated;


    const closeDialog = () => {
        modesOfTransport.setOpen(false);
    }

    return (


        <Dialog open={true}>
            <DialogTitle>
                <Typography>Motorbike Details</Typography>
                <IconButton
                    onClick={closeDialog}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                    }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {!activated && <MotorbikeDetails />}
                    {activated && <InsuranceDetails />}
                </DialogContentText>
            </DialogContent>
        </Dialog>

    )
})
export default MotorbikeDetailsForm;