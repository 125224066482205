import React, { useEffect } from "react";
import { observer, Observer } from "mobx-react-lite";
import { useAppState } from "../../../states/app-state";
import BearerTextField from "../../../components/fields/BearerTextField";
import { validatePassword } from "../../../components/validation/FieldsValidation";
import DisabledClickLoading from "../../../components/DisabledClickLoading";
import { Grid, Button, FormHelperText } from "@mui/material";
import { log } from "../../../components/error/error";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from "@mui/material";

const SecondStep: React.FC = observer(() => {
  const appState = useAppState();
  const { signUp } = appState.registration;
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [counter, setCounter] = React.useState(240);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [counterClass, setCounterClass] = React.useState("remaing-time green");
  const url =
    appState.remoteConfigBaseUrl !== null
      ? appState.remoteConfigBaseUrl.rider_onboarding_url
      : "";
  useEffect(() => {
    if (signUp.registrationEmail.length < 5) {
      logout();
      return;
    }

    const timer = setTimeout(() => {
      setCounter(counter - 1);
    }, 1000);
    if (counter < 120) {
      setCounterClass("remaing-time orange");
    }
    if (counter < 60) {
      setCounterClass("remaing-time red");
    }
    if (counter === 0) {
      clearTimeout(timer);
      signUp.setTimer(false);
      setDialogOpen(true);
    }
  }, [counter, counterClass]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleClick = () => {
    logout();
  };

  const logout = () => {
    try {
      setLoading(true);
      appState.login.logout();
      window.location.replace(url);
      setLoading(false);
    } catch (err: any) {
      setLoading(false);
    }
  };

  const handleSetPassword = async () => {
    try {
      if (signUp.registrationEmail.length < 5) {
        logout();
        return;
      }
      setLoading(true);
      setError("");
      await signUp.setUserPassword();
      setLoading(false);
    } catch (err: any) {
      log(err);
      setLoading(false);
      switch (err.message) {
        case "Firebase: Error (auth/requires-recent-login).":
          setError("The activation link you've used is expired.");
          return;
        case "Firebase: Error (auth/wrong-password)":
          setError("Incorrect password.");
          return;
        case "Firebase: Error (auth/user-not-found)":
          setError("User doesn’t exist.");
          return;
        default:
          setError(err?.message);
          break;
      }
    }
  };

  function secondsToTime(e: number) {
    const m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, "0"),
      s = Math.floor(e % 60)
        .toString()
        .padStart(2, "0");

    return m + ":" + s;
  }

  return (
    <>
      <Grid container spacing={1} sx={{ margin: "8px 0" }}>
        <Grid item sm={12} xs={12}>
          <h3 className={counterClass}>{secondsToTime(counter)}</h3>
        </Grid>

        <Grid item sm={6} xs={12}>
          <Observer>
            {() => (
              <>
                <BearerTextField
                  sx={{ paddingBottom: "10px" }}
                  label="Password"
                  type="password"
                  value={signUp.password}
                  onChange={(e: any) => signUp.setPassword(e.target.value)}
                  onValidate={(v: any) => signUp.setPasswordValid(v)}
                  validator={validatePassword}
                />
                {error && (
                  <FormHelperText error={!!error}>{error}</FormHelperText>
                )}
              </>
            )}
          </Observer>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Observer>
            {() => (
              <>
                <BearerTextField
                  sx={{ paddingBottom: "10px" }}
                  label="Confirm Password"
                  type="password"
                  value={signUp.confirmPassword}
                  onChange={(e: any) =>
                    signUp.setConfirmPassword(e.target.value)
                  }
                />
                {signUp.password !== signUp.confirmPassword && (
                  <FormHelperText error>
                    Please make sure your Passwords match.
                  </FormHelperText>
                )}
              </>
            )}
          </Observer>
        </Grid>
        <Observer>
          {() => {
            const valid =
              signUp.passwordValid &&
              signUp.password === signUp.confirmPassword;
            return (
              <>
                <Button
                  variant="contained"
                  sx={{ display: "block", margin: "20px auto 0 auto" }}
                  onClick={handleSetPassword}
                  disabled={!valid}
                >
                  Set Password
                </Button>
                {loading && <DisabledClickLoading />}
              </>
            );
          }}
        </Observer>
      </Grid>
      <Dialog open={dialogOpen}>
        <DialogContent>
          <DialogContentText>Session expired</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="primary" variant="contained" onClick={handleClick}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
});
export default SecondStep;
