import React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import BearerTextField from "../../../components/fields/BearerTextField";
import { validateGeneralText, validatePostCode, validateStreetNumber } from "../../../components/validation/FieldsValidation";
import { StatesEnumValues, useAppState } from "../../../states/app-state";
import { observer, Observer } from "mobx-react-lite";

const ManualLegalAddress: React.FC = observer(() => {
    const appState = useAppState();
    const { legalDetails } = appState.registration;
    return (
        <Grid container spacing={2}>

            <Grid item xs={12} sm={4}>
                <Observer>{() => (
                    <BearerTextField
                        label="Apt#"
                        inputProps={{
                            readOnly: !legalDetails.checked,
                        }}
                        value={legalDetails.aptNumber}
                        onChange={(e: any) => legalDetails.setAptNumber(e.target.value)}
                    />
                )}</Observer>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Observer>{() => (
                    <BearerTextField
                        label="Unit#"
                        inputProps={{
                            readOnly: !legalDetails.checked,
                        }}
                        value={legalDetails.unitNumber}
                        onChange={(e: any) => legalDetails.setUnitNumber(e.target.value)}
                    />
                )}</Observer>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Observer>{() => (
                    <BearerTextField
                        label="Street#"
                        inputProps={{
                            readOnly: !legalDetails.checked,
                        }}
                        value={legalDetails.streetNumber}
                        onChange={(e: any) => legalDetails.setStreetNumber(e.target.value)}
                        onValidate={(v: any) => legalDetails.setStreetNumberValid(v)}
                        validator={validateStreetNumber}
                    />
                )}</Observer>
            </Grid>
            <Grid item xs={12} sm={8}>
                <Observer>{() => (
                    <BearerTextField
                        label="Street Name"
                        inputProps={{
                            readOnly: !legalDetails.checked,
                        }}
                        value={legalDetails.streetName}
                        onChange={(e: any) => legalDetails.setStreetName(e.target.value)}
                        onValidate={(v: any) => legalDetails.setStreetNameValid(v)}
                        validator={validateGeneralText}
                    />
                )}</Observer>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Observer>{() => (
                    <BearerTextField
                        label={legalDetails.checked ? "Street Type" : ""}
                        inputProps={{
                            readOnly: !legalDetails.checked,
                        }}
                        value={legalDetails.streetType}
                        onChange={(e: any) => legalDetails.setStreetType(e.target.value)}
                        onValidate={(v: any) => legalDetails.setStreetTypeValid(v)}
                        validator={validateGeneralText}
                    />
                )}</Observer>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Observer>{() => (
                    <BearerTextField
                        label="City/Area"
                        inputProps={{
                            readOnly: !legalDetails.checked,
                        }}
                        value={legalDetails.city}
                        onChange={(e: any) => legalDetails.setCity(e.target.value)}
                        onValidate={(v: any) => legalDetails.setCityValid(v)}
                        validator={validateGeneralText}
                    />
                )}</Observer>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Observer>{() => (
                    <TextField
                        select
                        label="State"
                        value={legalDetails.state}
                        onChange={(e: any) => legalDetails.setState(e.target.value)}
                        variant="filled"
                        fullWidth
                        size="small"
                    >
                        {StatesEnumValues.map((state, index) => (
                            <MenuItem key={index} value={state}>
                                {state}
                            </MenuItem>
                        ))}
                    </TextField>
                )}</Observer>
            </Grid>
            <Grid item xs={12} sm={4}>
                <Observer>{() => (
                    <BearerTextField
                        label="Post Code"
                        inputProps={{
                            readOnly: !legalDetails.checked,
                        }}
                        value={legalDetails.postCode}
                        onChange={(e: any) => legalDetails.setPosteCode(e.target.value)}
                        onValidate={(v: any) => legalDetails.setPostCodeValid(v)}
                        validator={validatePostCode}
                    />
                )}</Observer>
            </Grid>
        </Grid>

    )
})
export default ManualLegalAddress;