import { IconButton, Tooltip } from '@mui/material';
import { observer, Observer } from 'mobx-react-lite';
import { useState } from 'react';
import DisabledClickLoading from '../../components/DisabledClickLoading';
import { useAppState } from '../../states/app-state';
import LogoutIcon from '@mui/icons-material/Logout';

const LogoutButton: React.FC = observer(() => {
    const appState = useAppState();
    const [loading, setLoading] = useState(false);
    const logout = () => {
        try {
            setLoading(true);
            appState.login.logout();
            setLoading(false);
        } catch (err: any) {
            setLoading(false);
        }
    }
    return (
        <Observer>{() => (
            <div style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "end"
            }}>

                <Tooltip title="Exit & Continue Later" placement="top-end">
                    <div>
                        <IconButton onClick={logout} sx={{ color: '#009eff' }}>
                            <LogoutIcon />
                        </IconButton>
                        {loading && <DisabledClickLoading />}
                    </div>
                </Tooltip>
            </div>

        )}</Observer>
    )
})
export default LogoutButton;