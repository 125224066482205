import React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { observer, Observer } from "mobx-react-lite";
import DisabledClickLoading from "../../components/DisabledClickLoading";
import BearerTextField from "../../components/fields/BearerTextField";
import { validateEmail } from "../../components/validation/FieldsValidation";
import { useAppState } from "../../states/app-state";
import { log } from "../../components/error/error";

const ResetPasswordPage: React.FC = observer(() => {
  const appState = useAppState();
  const { forgetPassword } = appState;

  const [openSuccessfulAlert, setOpenSuccessfulAlert] = React.useState(false);
  const handleOpenSuccessfulAlert = () => {
    setOpenSuccessfulAlert(true);
  };
  const handleSuccessfulAlertClose = () => {
    setOpenSuccessfulAlert(false);
  };
  const [loading, setLoading] = React.useState(false);
  const resetPassword = () => {
    try {
      setLoading(true);
      appState.forgetPassword.fetchResetPassword();
      handleOpenSuccessfulAlert();
      setLoading(false);
    } catch (err: any) {
      log(err);
      setLoading(false);
    }
  };
  const backHandler = () => {
    appState.login.setEditing(true);
  };
  return (
    <>
      <SuccessfulAlert
        open={openSuccessfulAlert}
        handleClose={handleSuccessfulAlertClose}
      />
      <Box
        sx={{
          width: "400px",
          padding: "20px",
          margin: "50px auto",
          boxShadow: "2",
        }}
      >
        <Typography>Forgotten your password?</Typography>
        <Typography>
          Please enter the Email address you used for the registration (Bearer
          username)
        </Typography>
        <Observer>
          {() => (
            <BearerTextField
              sx={{ marginY: "20px" }}
              label="Email"
              type="email"
              name="email"
              autoComplete="email"
              value={forgetPassword.email}
              onChange={(e: any) => forgetPassword.setEmail(e.target.value)}
              onValidate={(v: any) => forgetPassword.setEmailValid(v)}
              validator={validateEmail}
            />
          )}
        </Observer>
        <div style={{ display: "grid", marginTop: "10px" }}>
          <Observer>
            {() => {
              const valid = !!forgetPassword.emailValid;
              return (
                <>
                  <Button
                    variant="contained"
                    fullWidth
                    onClick={resetPassword}
                    disabled={!valid}
                  >
                    SUBMIT
                  </Button>
                  {loading && <DisabledClickLoading />}
                </>
              );
            }}
          </Observer>
          <Button onClick={backHandler} sx={{ marginTop: "10px" }}>
            BACK
          </Button>
        </div>
      </Box>
    </>
  );
});
export default ResetPasswordPage;

interface ISuccessfulAlertProps {
  open: boolean;
  handleClose: () => void;
}
const SuccessfulAlert: React.FC<ISuccessfulAlertProps> = ({
  open,
  handleClose,
}) => {
  const appState = useAppState();
  const okHandler = () => {
    appState.login.setEditing(true);
    handleClose();
  };
  return (
    <Dialog open={open}>
      <DialogTitle>
        <Typography variant="h6" align="center">
          Done!
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Typography>
            If you already have an account with Bearer, please check your email
            and follow the instructions to reset your password.
          </Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={okHandler}>
          Ok
        </Button>
      </DialogActions>
    </Dialog>
  );
};
