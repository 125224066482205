import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Typography,
  Link,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { ErrorEnum, useAppState } from "../states/app-state";

const ErrorMessages: React.FC = observer(() => {
  const appState = useAppState();
  const { errorMessage } = appState;
  if (!errorMessage) {
    return null;
  }
  return (
    <>
      {errorMessage.error === ErrorEnum.Unknown && <UnknownError />}
      {errorMessage.error === ErrorEnum.ServerError && <ServerError />}
      {errorMessage.error === ErrorEnum.Error400 && <Error400 />}
      {errorMessage.error === ErrorEnum.Error408 && <Error408 />}
      {errorMessage.error === ErrorEnum.Error409 && <Error409 />}
      {errorMessage.error === ErrorEnum.Error416 && <Error416 />}
      {errorMessage.error === ErrorEnum.Error417 && <Error417 />}
      {errorMessage.error === ErrorEnum.Error419 && <Error419 />}
      {errorMessage.error === ErrorEnum.Error428 && <Error428 />}
      {errorMessage.error === ErrorEnum.Error429 && <Error429 />}
      {errorMessage.error === ErrorEnum.RecentLoginError && (
        <RecentLoginError />
      )}
      {errorMessage.error === ErrorEnum.IpError && <IpError />}
    </>
  );
});
export default ErrorMessages;

const UnknownError: React.FC = () => {
  const appState = useAppState();
  const { errorMessage } = appState;
  if (!errorMessage) {
    return null;
  }
  const handleClick = () => {
    appState.setErrorMessage(undefined);
  };
  return (
    <>
      <Dialog open={true}>
        <DialogContent>
          <DialogContentText>{errorMessage?.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClick}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const RecentLoginError: React.FC = () => {
  const appState = useAppState();
  const { errorMessage } = appState;
  if (!errorMessage) {
    return null;
  }
  const handleClick = () => {
    appState.setErrorMessage(undefined);
    appState.registration.signUp.reauthenticateWithCredential();
  };
  return (
    <>
      <Dialog open={true}>
        <DialogContent>
          <DialogContentText>
            Session expired. Please check your email again and follow the
            provided instructions to continue the registration.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClick}>Ok</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

const ServerError: React.FC = observer(() => {
  const appState = useAppState();
  const { errorMessage } = appState;
  if (!errorMessage) {
    return null;
  }
  const handleClick = () => {
    appState.setErrorMessage(undefined);
  };
  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>{errorMessage?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
});

const Error400: React.FC = observer(() => {
  const appState = useAppState();
  const { errorMessage } = appState;
  if (!errorMessage) {
    return null;
  }
  const handleClick = () => {
    appState.setErrorMessage(undefined);
  };
  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
          <Typography>{errorMessage?.message}</Typography>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
});

const Error409: React.FC = observer(() => {
  const appState = useAppState();
  const { errorMessage } = appState;
  if (!errorMessage) {
    return null;
  }
  const handleClick = () => {
    appState.setErrorMessage(undefined);
  };
  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>{errorMessage?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
});

const Error416: React.FC = observer(() => {
  const appState = useAppState();
  const landingUrl = appState.onboardingRemoteConfigParameters?.landingpageUrl;
  const { errorMessage } = appState;
  if (!errorMessage) {
    return null;
  }
  const url = appState.url;
  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>
          <Typography>{errorMessage?.message}</Typography>
          <Link href={url} target="_parent" rel="noreferre">
            {landingUrl}
          </Link>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
});

const Error408: React.FC = observer(() => {
  const appState = useAppState();
  const { errorMessage } = appState;
  if (!errorMessage) {
    return null;
  }
  const handleClick = () => {};
  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>{errorMessage?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
});
const Error417: React.FC = observer(() => {
  const appState = useAppState();
  const { errorMessage } = appState;
  if (!errorMessage) {
    return null;
  }
  const handleClick = () => {
    appState.setErrorMessage(undefined);
  };
  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>{errorMessage?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
});

const IpError: React.FC = observer(() => {
  const appState = useAppState();
  const { errorMessage } = appState;
  if (!errorMessage) {
    return null;
  }
  const handleClick = () => {
    appState.setErrorMessage(undefined);
    window.location.reload();
  };
  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>{errorMessage?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
});

const Error419: React.FC = observer(() => {
  const appState = useAppState();
  const { errorMessage } = appState;
  if (!errorMessage) {
    return null;
  }
  const handleClick = () => {
    appState.setErrorMessage(undefined);
  };
  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>{errorMessage?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
});

const Error428: React.FC = observer(() => {
  const appState = useAppState();
  const { errorMessage } = appState;
  if (!errorMessage) {
    return null;
  }
  const handleClick = () => {
    appState.setErrorMessage(undefined);
  };
  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>{errorMessage?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
});

const Error429: React.FC = observer(() => {
  const appState = useAppState();
  const { errorMessage } = appState;
  if (!errorMessage) {
    return null;
  }
  const handleClick = () => {
    appState.setErrorMessage(undefined);
  };
  return (
    <Dialog open={true}>
      <DialogContent>
        <DialogContentText>{errorMessage?.message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClick}>Ok</Button>
      </DialogActions>
    </Dialog>
  );
});
